import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../core/language';
import mainImage from '../assets/images/contatti.jpeg';


export default function Contact() {
    return (
        <Container fluid className='p-0'>
            <div className='main'>

                <div className='slide-image' style={{ "backgroundImage": `url('${mainImage}')` }}>
                    <h2>{LanguageProvider.language.header.contact}</h2>
                </div>

                <Row className='text-center mb-5 mt-5' style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Col sm={6} className='p-5'>
                        <FontAwesomeIcon size={'2xl'} icon="phone" color="#e85454" className='mb-3' />
                        <p><b>{LanguageProvider.language.contact.phone}</b></p>
                        <p>+39 0434564815</p>
                    </Col>
                    <Col sm={6} className='p-5'>
                        <FontAwesomeIcon size={'2xl'} icon="envelope" color="#e85454" className='mb-3' />
                        <p><b>{LanguageProvider.language.contact.email}</b></p>
                        <p>supporto@kateigroup.com</p>
                    </Col>
                    {/*
                    <Col sm={4} className='p-5'>
                        <Icon path={mdiMapMarkerOutline} size={'48px'} color="#e85454" />
                        <p><b>{LanguageProvider.language.contact.address}</b></p>
                        <p>Via Prasecco, 17<br />33170 – Pordenone (PN)</p>
                    </Col>
                    */}
                </Row>

                {/*
                <iframe style={{ 'border': 0 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11095.47804805581!2d12.6847104!3d45.9538901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x85848f109690f2f0!2sKatei%20s.r.l.!5e0!3m2!1sit!2sit!4v1666093831479!5m2!1sit!2sit" width="100%" height="360" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                */}

            </div>
        </Container>
    );
};