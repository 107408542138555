import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Form, InputGroup, Tab, Row, Col, Nav, Modal, Carousel, Alert, Table, Image, Spinner, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { settings } from '../../core/settings';
import { sendDataToApi, insertItem, deleteItem } from '../../core/api';
import { toast } from 'react-toastify';

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


export default function Product({ refreshUserInfo }) {

    const { k } = useParams();
    const id = parseInt(k)
    const navigate = useNavigate();

    const [entity] = useState("get_user_product")
    const [data, setData] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [sid, setSid] = useState(null)
    const [like, setLike] = useState(0)
    const [indexCarousel, setIndexCarousel] = useState(0)
    const [category, setCategory] = useState(null)



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    function handleShow(i) {
        setIndexCarousel(i)
        setShow(true);
    }
    const handleSelect = (selectedIndex, e) => {
        setIndexCarousel(selectedIndex);
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            let token = localStorage.getItem('sid')
            setSid(token)
        }
        if (typeof id !== 'undefined') {
            getData(id)
        }
    }, [id]);

    async function getData(id) {
        var body = {
            "id": id,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        console.log('product')
        console.log(data)
        if (data.result === 'success') {
            setData(data.data.data);
            setLike(data.data.data.isInWishlist)
            setCategory(data.data.data.idProductCategory.id)
        }
        else {
            setData([])
        }
    }

    function lessQuantity() {
        if (quantity > 0) {
            setQuantity(quantity - 1)
        }
    }

    async function addToWishlist() {
        let body = {
            'idProduct': data.id
        }
        let response = await sendDataToApi(body, 'create', 'wishlist_product')
        if (response.result === 'success') {
            setLike(1)
        }
    }
    async function removeFromWishlist() {
        let body = {
            'idProduct': data.id
        }
        let response = await sendDataToApi(body, 'remove_wishlist_product')
        if (response.result === 'success') {
            setLike(0)
        }
    }

    async function addToCart() {
        if (quantity > 0) {
            let post = {
                'idProduct': data.id,
                'quantity': quantity
            }
            let result = await insertItem(post, 'cart_product')
            console.log(result)
            if (result === 'success') {
                refreshUserInfo()
            }
        }
        else {
            toast.info('Controlla la quantità e riprova', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    function backToCategory() {
        console.log(category)
        var urlName = data.idProductCategory.name.replace(/[\s-]+/g, '-').toLowerCase();
        navigate('/category/' + category + '-' + urlName)
    }


    if (data !== null && typeof data !== 'undefined' && Object.keys(data).length) {
        if (data.mainImage !== null) {
            var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + data.mainImage.id
        }
        else {
            var mainImage = 'https://kateihome.com/defaults/default.png'
        }
        if (data.dataSheet !== null) {
            var dataSheet = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + data.dataSheet.id
        }
        else {
            var dataSheet = null
        }
        return (
            <>
                <Helmet>
                    <title>{data.name} | Katei Home</title>
                    <meta name='description' content={"Acquista l'articolo: " + data.name}></meta>
                </Helmet>
                <Container fluid="md" className='mb-5 mt-3'>
                    <div className='product--page'>

                        <Button className='mb-2 d-flex align-items-center' onClick={backToCategory} size={'lg'} variant="outline-secondary">
                            <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                        </Button>

                        <Card style={{ backgroundColor: '#f9f9f9' }}>
                            <Card.Body style={{ backgroundColor: '#f9f9f9' }}>
                                <Row className='product-table w-100'>
                                    <Col xs={12} lg={6}>
                                        <img onClick={() => handleShow(0)} src={mainImage} />
                                        <div className='product-gallery'>
                                            {data.images.map((item, i) => {
                                                var image = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.id
                                                var j = i + 1
                                                return (
                                                    <li key={i} onClick={() => handleShow(j)}>
                                                        <img src={image} />
                                                    </li>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <span className='available d-flex align-items-center justify-content-end'>
                                            {data.isAvailable === '1' ?
                                                (
                                                    <>
                                                        <FontAwesomeIcon size={'lg'} icon="circle" color="#81a432" className='me-1' />
                                                        {LanguageProvider.language.checkout.available}
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon size={'lg'} icon="circle" color="#f2dc23" className='me-1' />
                                                        {LanguageProvider.language.checkout.notAvailable}
                                                    </>
                                                )
                                            }
                                        </span>
                                        <h1>{data.name}</h1>
                                        <p className='price'>
                                            {data.discountedPrice !== null ?
                                                (
                                                    <><s>{data.price}</s> {data.discountedPrice} €</>
                                                ) : (
                                                    <>{data.price} €</>
                                                )
                                            }
                                        </p>

                                        <div className='content'>
                                            <div className="mt-3" dangerouslySetInnerHTML={{ __html: data.description }}></div>

                                            <Container fluid className='mb-5 mt-5 p-0'>
                                                {data.variants !== null && data.variants.length > 1 ?
                                                    (
                                                        <>
                                                            <p><b>{LanguageProvider.language.product.variants}</b></p>
                                                            <div className='variants-container'>
                                                                {data.variants.map((variant, i) => {
                                                                    var className = 'variant-item'
                                                                    if (id === variant.id) {
                                                                        className = 'variant-item selected';
                                                                    }
                                                                    var urlName = variant.idProductHead.name.replace(/[\s-]+/g, '-').toLowerCase();
                                                                    console.log(variant)
                                                                    var linkVariant = '/product/' + variant.id + '-' + urlName
                                                                    if (variant.variantImage !== null)
                                                                        var imageV = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + variant.variantImage.id
                                                                    return (
                                                                        <div className={className} key={i}>
                                                                            {variant.variantImage !== null ?
                                                                                (
                                                                                    <Link to={linkVariant}>
                                                                                        <img src={imageV} width='60' />
                                                                                    </Link>
                                                                                ) : (
                                                                                    <>No image</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                }

                                            </Container>
                                        </div>

                                        {sid !== null ?
                                            (
                                                <>
                                                    <InputGroup className="mb-3" style={{ "width": 120 }}>
                                                        <Button onClick={() => lessQuantity()} variant="secondary" style={{ 'width': 36 }}>
                                                            -
                                                        </Button>
                                                        <Form.Control
                                                            className='text-center'
                                                            value={quantity}
                                                            disabled
                                                        />
                                                        <Button onClick={() => setQuantity(quantity + 1)} variant="secondary" style={{ 'width': 36 }}>
                                                            +
                                                        </Button>
                                                    </InputGroup>

                                                    <Button onClick={() => addToCart()} variant="outline-success">{LanguageProvider.language.product.addToCart} <FontAwesomeIcon size={'lg'} icon="shopping-cart" /></Button>
                                                    {like == '0' ?
                                                        (
                                                            <Button onClick={() => addToWishlist()} className='ms-3' variant="outline-light"><FontAwesomeIcon size={'lg'} icon="heart" color="#999" /></Button>
                                                        ) : (
                                                            <Button onClick={() => removeFromWishlist()} className='ms-3' variant="outline-light"><FontAwesomeIcon size={'lg'} icon="heart" color="#ff4400" /></Button>
                                                        )
                                                    }
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <Alert className='mt-3' variant={'info'}>
                                                        <Alert.Link href="/login">Accedi</Alert.Link>{' '}per poter acquistare questo articolo.
                                                    </Alert>
                                                </>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>
                </Container>


                <Modal show={show} onHide={handleClose} size="lg" scrollable={true}>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <Carousel activeIndex={indexCarousel} onSelect={handleSelect} className='carousel-dark'>
                            <Carousel.Item>
                                <img src={mainImage} height={'500px'} width={'100%'} style={{ objectFit: 'contain' }} />
                            </Carousel.Item>
                            {data.images.map((item, i) => {
                                var image = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.id
                                return (
                                    <Carousel.Item key={i}>
                                        <img src={image} height={'500px'} width={'100%'} style={{ objectFit: 'contain' }} />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </Modal.Body>
                </Modal>


                <Container id="left-tabs-product" fluid="md" className='mb-5 mt-3'>
                    <Tab.Container id="left-tabs-1" defaultActiveKey="details">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="details">{LanguageProvider.language.product.details}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="manufacturer">{LanguageProvider.language.product.manufacturer}</Nav.Link>
                                    </Nav.Item>
                                    {data.videoEmbedCode !== null ?
                                        (
                                            <Nav.Item>
                                                <Nav.Link eventKey="video">{LanguageProvider.language.product.video}</Nav.Link>
                                            </Nav.Item>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {/*
                                    <Nav.Item>
                                        <Nav.Link eventKey="reviews">{LanguageProvider.language.product.reviews}</Nav.Link>
                                    </Nav.Item>
                                    */}
                                </Nav>
                            </Col>
                            <Col sm={9} id="tab-product-content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="details">
                                        <h3>{LanguageProvider.language.product.detailsTitle}</h3>
                                        <table className='product-details'>
                                            <tbody>
                                                {data.length !== null && data.length > 0 ?
                                                    (
                                                        <tr>
                                                            <th>{LanguageProvider.language.product.length}</th>
                                                            <td>{data.length} cm</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {data.height !== null && data.height > 0 ?
                                                    (
                                                        <tr>
                                                            <th>{LanguageProvider.language.product.height}</th>
                                                            <td>{data.height} cm</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {data.width !== null && data.width > 0 ?
                                                    (
                                                        <tr>
                                                            <th>{LanguageProvider.language.product.width}</th>
                                                            <td>{data.width} cm</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {data.weight !== null && data.weight > 0 ?
                                                    (
                                                        <tr>
                                                            <th>{LanguageProvider.language.product.weight}</th>
                                                            <td>{data.weight} kg</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                {data.volume !== null && data.volume > 0 ?
                                                    (
                                                        <tr>
                                                            <th>{LanguageProvider.language.product.volume}</th>
                                                            <td>{data.volume} m³</td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <p className='datasheet mt-4'>
                                            {dataSheet !== null ?
                                                (
                                                    <a target='_blank' href={dataSheet}>
                                                        <Button variant='outline-secondary' size='sm'>
                                                            Download <b>{LanguageProvider.language.product.datasheet}</b>
                                                        </Button>
                                                    </a>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="manufacturer">
                                        <h3>{LanguageProvider.language.product.manufacturerTitle}</h3>
                                        <p>
                                            <b>{data.idManufacturer.name}</b><br />
                                            {data.idManufacturer.vatNumber}<br />
                                            {data.idManufacturer.address} - {data.idManufacturer.city} - {data.idManufacturer.postalCode}<br />
                                            {data.idManufacturer.email}
                                        </p>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="video">
                                        <h3>{LanguageProvider.language.product.videoTitle}</h3>
                                        <div className='content' dangerouslySetInnerHTML={{ __html: data.videoEmbedCode }}></div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="reviews">
                                        <h3>{LanguageProvider.language.product.reviewsTitle}</h3>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>

                {data.relatedProducts.length > 0 ?
                    (
                        <Container fluid="md" className='mb-5 mt-5'>
                            <center>
                                <h3>{LanguageProvider.language.product.related}</h3>
                            </center>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                navigation={false}
                                grabCursor={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                }}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {data.relatedProducts.map((item, i) => {
                                    var urlName = item.name.replace(/[\s-]+/g, '-').toLowerCase();
                                    let url = '/product/' + item.id + '-' + urlName;
                                    if (item.mainImage !== null) {
                                        var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                    }
                                    else {
                                        var mainImage = 'https://kateihome.com/defaults/default.png'
                                    }

                                    return (
                                        <SwiperSlide key={i} className='mb-3'>
                                            <div className='item--product'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className='product-grid-image-container'>
                                                                    <Link to={url}>
                                                                        <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <Link to={url}>
                                                                    <h3>{item.name}</h3>
                                                                </Link>
                                                                <p className='price'>
                                                                    {item.discountedPrice !== null ?
                                                                        (
                                                                            <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                        ) : (
                                                                            <>{item.price} €</>
                                                                        )
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </Container>
                    ) : (
                        <></>
                    )
                }


                <div id='wa-fixed'>
                    <a href={`https://wa.me/${data.idManufacturer.whatsAppPhoneNumber}`} target='_blank'>
                        <Button size={'lg'} variant="success" className='me-2'><FontAwesomeIcon size={'2xl'} icon="comment-dots" /></Button>
                    </a>
                </div>
            </>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};