var langs = []

langs['ita'] = {
    defaults: {
        noResults: 'Nessun risultato trovato.',
        notFound: 'Pagina non trovata.',
        view: 'Visualizza',
        edit: 'Modifica',
        delete: 'Elimina',
        deleteTitle: 'Elimina elemento',
        deleteMsg: 'Confermi di voler rimuovere questo elemento?',
        details: 'Dettagli',
        close: 'Chiudi',
        open: 'Apri',
        error: 'Errore',
        save: 'Salva',
        search: 'Cerca',
        create: 'Crea nuovo',
        resultsPerPage: 'Risultati per pagina',
        more: 'Scopri di più',
        success: 'Operazione effettuata con successo.',
        no: 'No',
        yes: 'Sì',
        goBack: 'Torna indietro',
        terms: 'Termini e Condizioni',
        alert: 'Attenzione'
    },
    header: {
        home: 'Homepage',
        about: 'Chi siamo',
        brand: 'Brand',
        products: 'Prodotti',
        promo: 'Promozioni',
        news: 'News',
        contact: 'Contatti',
        profile: 'Il mio profilo'
    },
    footer: {
        title1: 'Sitemap',
        title2: 'Categorie',
        title3: 'Seguici',
        title4: 'Hai bisogno d\'aiuto?',
        title5: 'Supporto',
        title6: 'Ordini',
        title7: 'Press',
        title8: 'Legal'
    },
    contact: {
        phone: 'Telefono',
        email: 'Email',
        address: 'Indirizzo'
    },
    login: {
        title: 'Effettua il login',
        username: 'Email',
        password: 'Password',
        login: 'Accedi',
        forgot: 'Hai dimenticato la password?',
        remember: 'Ricordami',
        register: 'Non sei registrato?',
        click: 'Clicca qui',
        recover: 'Hai dimenticato la password?',
        error: 'Errore di autenticazione. Riprova.'
    },
    recover: {
        title: 'Recupera la password',
        send: 'Invia',
        confirm: 'Conferma',
        key: 'Pin',
        insertKey: 'Inserisci il pin che hai ricevuto alla mail e la nuova password.'
    },
    logout: {
        title: 'Stai eseguendo il logout.',
        subtitle: 'Premendo il tasto logout verrai reindirizzato alla pagina di login.'
    },
    register: {
        title: 'Registrati',
        register: 'Registrati',
        login: 'Hai già un account?',
        click: 'Clicca qui',
        password: 'Password',
        name: 'Nome',
        surname: 'Cognome',
        email: 'Email',
        success: 'Complimenti. Ti sei registrato con successo.',
        fail: 'Errore. Questo indirizzo email è già presente o alcuni campi non sono validi.'
    },
    profile: {
        profile: 'Profilo',
        subtitle: 'Gestisci il tuo profilo.',
        general: 'Generali',
        password: 'Password',
        name: 'Nome',
        surname: 'Cognome',
        username: 'Username',
        save: 'Salva anagrafica',
        updatePassword: 'Aggiorna password',
        newPassword: 'Nuova password',
        confirmPassword: 'Conferma password',
        oldPassword: 'Password attuale',
        passwordUpdated: 'Password aggiornata con successo.',
        passwordMatch: 'Le password non coincidono',
        logout: 'Logout',
        wishlist: 'Lista Desideri',
        orders: 'Ordini',
        address: 'Indirizzi',
        shippingAddress: 'Indirizzi di Spedizione',
        billingAddress: 'Indirizzi di Fatturazione'
    },
    product: {
        addToCart: 'Aggiungi al carrello',
        chat: 'Contatta il produttore',
        phone: 'Telefono',
        details: 'Caratteristiche',
        video: 'Video',
        reviews: 'Recensioni',
        detailsTitle: 'Informazioni sul prodotto',
        videoTitle: 'Video dimostrativo',
        reviewsTitle: 'Recensioni dei clienti',
        width: 'Larghezza',
        length: 'Lunghezza',
        height: 'Altezza',
        weight: 'Peso',
        volume: 'Volume',
        boxWidth: 'Larghezza imballo',
        boxLength: 'Lunghezza imballo',
        boxHeight: 'Altezza imballo',
        boxWeight: 'Peso imballo',
        manufacturer: 'Produttore',
        manufacturerTitle: 'Informazioni sul produttore',
        datasheet: 'Scheda tecnica',
        variants: 'Varianti',
        goToCategory: 'Vai alla categoria',
        related: 'Prodotti correlati'
    },
    home: {
        last: 'Ultimi arrivi',
        sell: 'I più venduti',
        brand: 'Naviga per brand'
    },
    labels: {
        id: 'id',
        name: 'Nome',
        surname: 'Cognome',
        email: 'Email',
        businessName: 'Ragione sociale',
        address: 'Indirizzo',
        city: 'Città',
        postalCode: 'Codice postale',
        taxCode: 'Codice fiscale',
        vatNumber: 'P.iva',
        pec: 'PEC',
        sdi: 'SDI',
        province: 'Provincia',
        floor: 'Piano',
        stairsSize: 'Dimensione scala',
        elevatorSize: 'Dimensione ascensore',
        phoneNumber: 'Numero di telefono',
        parking: 'Parcheggio e ZTL'
    },
    category: {
        filters: 'Filtri',
        fastDelivery: 'Pronta consegna',
        apply: 'Applica filtri'
    },
    cart: {
        title: 'Il tuo carrello',
        goToCheckout: 'Vai al checkout'
    },
    checkout: {
        cart: 'Carrello',
        shipping: 'Spedizione',
        warehouse: 'Magazzino',
        payment: 'Pagamento',
        title: 'Completa il tuo ordine',
        selectShipping: 'Seleziona indirizzo di spedizione',
        selectBilling: 'Seleziona indirizzo di fatturazione',
        selectWarehouse: 'Seleziona magazzino',
        goToPayment: 'Vai al pagamento',
        recap: 'Riepilogo ordine',
        totalProducts: 'Totale prodotti',
        totalShipping: 'Totale spedizione e montaggio',
        totalOrder: 'Totale ordine (IVA inclusa)',
        shippingDays: 'Giorni consegna',
        shippingPrice: 'Costo consegna',
        available: 'Pronta Consegna',
        notAvailable: 'Disponibile',
        message: "L'ambiente deve essere libero per consentire il montaggio.",
        useShippingAddress: "Usa l'indirizzo di spedizione.",
        shippingError: 'Seleziona un indirizzo di spedizione e fatturazione per proseguire.'
    },
    about: {
        title1: 'DAL PRODUTTORE A CASA VOSTRA',
        title2: 'TRASPORTO SICURO',
        title3: "MONTAGGIO A REGOLA D'ARTE",
        subtitle1: 'per farvi risparmiare senza rinunciare alla qualità',
        subtitle2: 'a tutela del vostro nuovo arredamento',
        subtitle3: 'eseguito da montatori esperti in tempi rapidi',
        description: 'Katei Home non è un semplice e-commerce dedicato al mondo dell’arredamento, ma un modo di pensare. Pensare in grande per ridurre al minimo la distanza tra produttori e clienti, attraverso una serie di servizi vantaggiosi ideati per portare a casa vostra tutta la qualità dei nostri prodotti, a un prezzo sempre conveniente. Ogni brand presente nel nostro sito è stato accuratamente selezionato per rispondere al meglio alla nostra filosofia e, soprattutto, alle vostre esigenze. Il team di Katei Home non lascia nulla al caso, nemmeno il trasporto e il montaggio. Il vostro arredamento, infatti, viene affidato alle mani esperte dei nostri montatori, professionisti con formazione accademica i quali si occupano di recapitare direttamente a casa vostra i prodotti acquistati, e di assemblarli con la massima cura. Anche nel caso di arredi di grandi dimensioni. Così a voi non resta che godervi lo spettacolo del risultato finale.'
    },
    order: {
        totalProducts: 'Totale prodotti',
        totalShipping: 'Totale spedizione e montaggio',
        totalOrder: 'Totale ordine',
        date: 'Data ordine',
        products: 'Prodotti',
        total: 'Totale',
        deliveryDate: 'Data e ora consegna',
        status: 'Stato ordine',
        name: 'Nome',
        quantity: 'Qta',
        address: 'Indirizzo'
    }
};


langs['eng'] = {
    defaults: {
        noResults: 'No results',
        notFound: 'Page not found',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        deleteTitle: 'Delete item',
        deleteMsg: 'Are you sure you want to delete this item?',
        details: 'Details',
        close: 'Close',
        open: 'Open',
        error: 'Error',
        save: 'Save',
        search: 'search',
        create: 'Create new',
        resultsPerPage: 'Results per page',
        more: 'See more',
        success: 'operation successfully completed',
        no: 'No',
        yes: 'Yes',
        goBack: 'Go back',
        terms: 'Terms and Conditions',
        alert: 'Attention'
    },
    header: {
        home: 'Homepage',
        about: 'About us',
        brand: 'Brand',
        products: 'Products',
        promo: 'Promo',
        news: 'News',
        contact: 'Contact',
        profile: 'Profile'
    },
    footer: {
        title1: 'Sitemap',
        title2: 'Categories',
        title3: 'Follow us',
        title4: 'Do you need help?',
        title5: 'Support',
        title6: 'Orders',
        title7: 'Press',
        title8: 'Legal'
    },
    contact: {
        phone: 'Phone',
        email: 'E-mail',
        address: 'Address'
    },
    login: {
        title: 'Login',
        username: 'Email',
        password: 'Password',
        login: 'Login',
        forgot: 'Do you forgot your password?',
        remember: 'Remember',
        register: 'Not yet registered?',
        click: 'Click here',
        recover: 'Forgot your password?',
        error: 'Auth error. Please retry.'
    },
    recover: {
        title: 'Recover your password',
        send: 'Send',
        confirm: 'Confirm',
        key: 'Key',
        insertKey: 'Insert pin you have received in your email folder and new password'
    },
    logout: {
        title: 'Logging out',
        subtitle: 'By clicking the logout button you will be redirected to the login page'
    },
    register: {
        title: 'Sign in',
        register: 'Sign in',
        login: 'Do you already have an account?',
        click: 'Click here',
        password: 'Password',
        name: 'Name',
        surname: 'Surname',
        email: 'E-mail',
        success: 'Compliments. You have registered successfully.',
        fail: 'Error. This email address is already present or some fields are invalid.'
    },
    profile: {
        profile: 'Profile',
        subtitle: 'Manage your profile',
        general: 'General',
        password: 'Password',
        name: 'Name',
        surname: 'Surname',
        username: 'Username',
        save: 'Save profile',
        updatePassword: 'update password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        oldPassword: 'Current password',
        passwordUpdated: 'Password updated',
        passwordMatch: 'Passwords do not match',
        logout: 'Logout',
        wishlist: 'Wishlist',
        orders: 'Orders',
        address: 'addresses',
        shippingAddress: 'Shipping addresses',
        billingAddress: 'Billing address'
    },
    product: {
        addToCart: 'Add to cart',
        chat: 'Contact the manufacturer',
        phone: 'Phone',
        details: 'Details',
        video: 'Video',
        reviews: 'Reviews',
        detailsTitle: 'Product info',
        videoTitle: 'Video tutorial',
        reviewsTitle: 'Customers review',
        width: 'Width',
        length: 'Lenght',
        height: 'Height',
        weight: 'Weight',
        volume: 'Volume',
        boxWidth: 'Box width',
        boxLength: 'Box length',
        boxHeight: 'Box heigth',
        boxWeight: 'Box weight',
        manufacturer: 'Manufacturer',
        manufacturerTitle: 'Manufacturer info',
        datasheet: 'Datasheet',
        variants: 'Variants',
        goToCategory: 'Go to category',
        related: 'Related products'
    },
    home: {
        last: 'Lastest arrivals',
        sell: 'Best sellers',
        brand: 'Browse by brand'
    },
    labels: {
        id: 'Id',
        name: 'Name',
        surname: 'Surname',
        email: 'E-mail',
        businessName: 'Business name',
        address: 'Address',
        city: 'City',
        postalCode: 'Postal code',
        taxCode: 'Tax code',
        vatNumber: 'Vat number',
        pec: 'Pec',
        sdi: 'Sdi',
        province: 'Province',
        floor: 'Floor',
        stairsSize: 'Stairs size',
        elevatorSize: 'Elevator size',
        phoneNumber: 'Phone number',
        parking: 'Parking'
    },
    category: {
        filters: 'Filters',
        fastDelivery: 'Fast delivery',
        apply: 'Apply filters'
    },
    cart: {
        title: 'You cart',
        goToCheckout: 'Go to checkout'
    },
    checkout: {
        cart: 'Cart',
        shipping: 'Shipping',
        warehouse: 'Warehouse',
        payment: 'Payment',
        title: 'Complete your order',
        selectShipping: 'Select shipping address',
        selectBilling: 'Select billing address',
        selectWarehouse: 'Select warehouse',
        goToPayment: 'Go to payment',
        recap: 'Order recap',
        totalProducts: 'Total products',
        totalShipping: 'Total shipping',
        totalOrder: 'Total order',
        shippingDays: 'Shipping days',
        shippingPrice: 'Shipping price',
        available: 'Fast Delivery',
        notAvailable: 'Available',
        message: "L'ambiente deve essere libero per consentire il montaggio.",
        useShippingAddress: "Use shipping address",
        shippingError: 'Seleziona un indirizzo di spedizione e fatturazione per proseguire.'
    },
    about: {
        title1: 'FROM THE MANUFACTURER TO HOME',
        title2: 'SAFE DELIVERY',
        title3: "PERFECT ASSEMBLY",
        subtitle1: 'To save your money without giving up the quality',
        subtitle2: 'To protect your new furniture',
        subtitle3: 'Performed quickly by expert fitters',
        description: 'Not a simply e-commerce furniture. Katei Home is a way of thinking. We think big to reduce the distance between manufacturers and customers thanks to a rage of services created to bring you the quality of our products. Each brand on Katei Home e-commerce is in line both with our philosophy and your needs. Here nothing is left to chance, neither the transport or the assembling. Your furniture is entrusted to our academic trained expert fitters in order to guarantee the assembling quality you deserve. Also in case of big furniture. So all you have to do is to enjoy the final result.'
    },
    order: {
        totalProducts: 'Total products',
        totalShipping: 'Total shipping',
        totalOrder: 'Total order',
        date: 'Order date',
        products: 'Products',
        total: 'Total',
        deliveryDate: 'Delivery date and time',
        status: 'Status order',
        name: 'Name',
        quantity: 'Qty',
        address: 'Address'
    }
};

const fields = {
    stairsSize: {
        '0_SMALL': 'Minore di un metro',
        '10_LARGE': 'Maggiore di un metro',
        '0_0_NONE': 'Scale non presenti'
    },
    elevatorSize: {
        '0_NONE': 'Non presente',
        '10_SMALL': 'Piccolo: L. 90 x P. 120 cm',
        '20_MEDIUM': 'Medio: L. 100 x P. 140 cm ',
        '30_LARGE': 'Grande: > L. 140 cm'
    },
    parking: {
        '0_NONE': 'Non presente',
        '10_NO_ZTL': 'Presente, no ZTL',
        '20_ZTL': 'Presente, sì ZTL',
        '30_NO_ZTL_PRENOTATION': 'Presente con prenotazione, no ZTL',
        '40_ZTL_PRENOTATION': 'Presente con prenotazione, sì ZTL',
    }
}

export function translateField(key, field) {
    if(fields[field]) {
        if(fields[field][key]) {
            return fields[field][key]
        }
        else {
            return key
        }
    }
    else {
        return key
    }
}

function changeLanguage(l) {
    if (typeof window !== "undefined") {
        localStorage.setItem('language', l);
        window.location.reload();
    }
}

function getLanguageCode(l) {
    if (typeof window !== "undefined") {
        var l = localStorage.getItem('language') || 'ita'
        if(l === 'ita') {
            return 'it'
        }
        else {
            return 'en'
        }
    }
    else {
        return 'it'
    }
}

export const LanguageProvider = {
    changeLanguage: changeLanguage,
    language: langs[localStorage.getItem('language') || 'ita'],
    getLanguageCode: getLanguageCode
};