import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import { LanguageProvider, translateField } from '../../core/language';


export default function ModalView({ data, rowView, tableConfig, closeModalView }) {

    const [statusModalView, setStatusModalView] = useState(false)

    useEffect(() => {
        if (data === null)
            setStatusModalView(false)
        else
            setStatusModalView(true)
    }, [data]);


    if (tableConfig !== null && data !== null) {
        return (
            <>
                <Modal show={statusModalView} onHide={closeModalView} size="lg" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.language.defaults.details}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered hover responsive>
                            <tbody>
                                {rowView.map((key, i) => {
                                    var type = tableConfig.fields[key].type;
                                    
                                    switch (type) {
                                        case 'externalEntity':
                                            var label = tableConfig.fields[key].options.labelField;
                                            return (
                                                <tr key={i}>
                                                    <td>{LanguageProvider.language.labels[key]}</td>
                                                    {tableConfig.fields[key].options.multiple === true ? (
                                                        <td>
                                                            {typeof data[key] !== 'undefined' ? (
                                                                <>
                                                                    {(data[key]).map((item, j) => {

                                                                        return (
                                                                            <div key={j}>
                                                                                {item[label]}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            {data[key].label}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        case 'option':
                                            return (
                                                <tr key={i}>
                                                    <td>{LanguageProvider.language.labels[key]}</td>
                                                    <td>{translateField(data[key], key)}</td>
                                                </tr>
                                            );
                                        default: return (
                                            <tr key={i}>
                                                <td>{LanguageProvider.language.labels[key]}</td>
                                                <td>{data[key]}</td>
                                            </tr>
                                        );
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModalView}>
                            {LanguageProvider.language.defaults.close}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};