import React, { useState, useEffect } from 'react';
import { Link, } from 'react-router-dom';
import { Container, Spinner, Alert } from 'react-bootstrap';
import { LanguageProvider } from '../core/language';
import { sendDataToApi } from '../core/api';


export default function Products() {

    const [entity] = useState("get_user_categories")
    const [data, setData] = useState(null)


    useEffect(() => {
        getData()
    }, []);


    async function getData() {
        var body = {
            "getAlsoEmptyCategories": true,
            "idProductCategoryParent": null,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log(data.data.data)
            setData(data.data.data.categories);
        }
        else {
            console.log(data)
            setData({})
        }
    }


    if (data !== null && typeof data !== 'undefined') {
        return (
            <Container fluid className='p-0'>
                <div className='main'>
                    {data.map((item, i) => {
                        var urlName = item.category.name.replace(/[\s-]+/g, '-').toLowerCase();
                        if (item.info.hasChild === true) {
                            var url = '/categories/' + item.category.id + '-' + urlName;
                        }
                        else {
                            var url = '/category/' + item.category.id + '-' + urlName;
                        }
                        if (item.category.mainImagePath !== null) {
                            var mainImage = item.category.mainImagePath
                        }
                        else {
                            var mainImage = 'https://kateihome.com/defaults/default.png'
                        }
                        return (
                            <div key={i} className='slide-category-container' style={{ "backgroundImage": `url('${mainImage}')` }}>
                                <Link to={url}>
                                    <div className='slide--category'>
                                        <h1>{item.category.name}</h1>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </Container>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};