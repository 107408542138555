import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Form, Button, Breadcrumb, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { insertItem, propertiesItem, getItems, sendDataToApi } from '../../core/api';
import ModalAdd from '../../components/ModalAddShipping';
import { settings } from '../../core/settings';
import { toast } from 'react-toastify';


export default function C_Shipping() {

    const navigate = useNavigate();

    //data
    const [shippingAddress, setShippingAddress] = useState(null)
    const [billingAddress, setBillingAddress] = useState(null)
    const [checkout, setCheckout] = useState({'shipping_address': null, 'billing_address': null})

    const [modalAddData, setModalAddData] = useState(null)
    const [modalAddEntity, setModalAddEntity] = useState(null)
    const [modalAddProp, setModalAddProp] = useState(null)
    const [modalAddRow, setModalAddRow] = useState(null)

    const [summary, setSummary] = useState(null)


    useEffect(() => {
        init()
    }, []);

    async function init() {
        await getShippingAddress()
        await getBillingAddress()
        await getSummary()
    }


    async function getSummary() {
        let data = await sendDataToApi({}, 'get_pending_order_summary')
        console.log('summary')
        console.log(data)
        setSummary(data.data.data)
    }

    async function getShippingAddress() {
        let data = await getItems(1, 20, '_shipping_address', ["id", "address", "name", "surname", "city", "province", "businessName", "floor", "stairsSize", "elevatorSize", "parking"], { "field": "id", "order": "asc" })
        if (data.result === 'success') {
            setShippingAddress(data.data.data)
            if(data.data.data.length > 0) {
                setChange('shipping_address', data.data.data[0].id)
            }
        }
    }
    async function getBillingAddress() {
        let data = await getItems(1, 20, '_billing_address', ["id", "address", "name", "surname", "city", "province", "businessName", "taxCode", "vatNumber", "sdi", "pec"], { "field": "id", "order": "asc" })
        if (data.result === 'success') {
            setBillingAddress(data.data.data)
            if(data.data.data.length > 0) {
                setChange('billing_address', data.data.data[0].id)
            }
        }
    }
    async function openModalShipping() {
        let prop = await getProperties('_shipping_address')
        setModalAddProp(prop)
        setModalAddRow(["address", "businessName", "city", "province", "postalCode", "name", "surname", "phoneNumber", "floor", "stairsSize", "elevatorSize", "parking"])
        setModalAddEntity('_shipping_address')
        let temp = Date.now();
        setModalAddData(temp)
    }
    async function openModalBilling() {
        let prop = await getProperties('_billing_address')
        setModalAddProp(prop)
        setModalAddRow(["address", "businessName", "city", "province", "postalCode", "name", "surname", "taxCode", "vatNumber", "sdi", "pec"])
        setModalAddEntity('_billing_address')
        let temp = Date.now();
        setModalAddData(temp)
    }
    async function saveModalAdd(data) {
        let result = await insertItem(data, modalAddEntity)
        if (result === 'success') {
            closeModalAdd()
            init()
        }
    }
    function closeModalAdd() {
        setModalAddData(null)
    }


    function setChange(key, value) {
        setCheckout((prevState) => ({
            ...prevState,
            [key]: value
        }));
    }
    async function getProperties(entity) {
        let data = await propertiesItem(entity)
        if (data.result === 'success') {
            return data.data.data;
        }
    }


    async function goToWarehouse() {
        console.log(checkout)
        let send = {
            idBillingAddress: checkout.billing_address,
            idShippingAddress: checkout.shipping_address
        }
        let data = await sendDataToApi(send, 'set_pending_order_addresses')
        console.log(data)
        if(data.result === 'error') {
            toast.error(LanguageProvider.language.checkout.shippingError, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            navigate('/checkout/warehouses')
        }
    }



    if (shippingAddress !== null && billingAddress !== null && summary !== null) {
        return (
            <>
                <Container fluid="md" className="container-lg mt-3 mb-5">

                    <Breadcrumb>
                        <Breadcrumb.Item linkProps={{ to: '/cart' }} linkAs={Link}>
                            {LanguageProvider.language.checkout.cart}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{LanguageProvider.language.checkout.shipping}</Breadcrumb.Item>
                    </Breadcrumb>

                    <h2>{LanguageProvider.language.checkout.title}</h2>

                    <Row>
                        <Col sm={12} md={9} className='p-2'>
                            <Card>
                                <Card.Body>
                                    <div className='row'>
                                        <div className="mb-5 col-sm-12 col-md-6">
                                            <label>{LanguageProvider.language.checkout.selectShipping}</label>
                                            <Form.Select onChange={(event) => setChange('shipping_address', event.target.value)} value={checkout['shipping_address']}>
                                                {shippingAddress.map((option, i) => {
                                                    return (
                                                        <option key={i} value={option.id}>{option.address}, {option.city}, {option.name} {option.surname}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            <br/>
                                            <Button variant="outline-secondary" size={'sm'} onClick={openModalShipping}>
                                                {LanguageProvider.language.defaults.create}
                                            </Button>
                                        </div>
                                        <div className="mb-5 col-sm-12 col-md-6">
                                            <label>{LanguageProvider.language.checkout.selectBilling}</label>
                                            <Form.Select onChange={(event) => setChange('billing_address', event.target.value)} value={checkout['billing_address']}>
                                                {billingAddress.map((option, i) => {
                                                    return (
                                                        <option key={i} value={option.id}>{option.address}, {option.city}, {option.name} {option.surname}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            <br/>
                                            <Button variant="outline-secondary" size={'sm'} onClick={openModalBilling}>
                                                {LanguageProvider.language.defaults.create}
                                            </Button>
                                        </div>
                                    </div>

                                    <br/>
                                    <center>
                                        <Button size={'lg'} variant="primary" onClick={goToWarehouse}>{LanguageProvider.language.checkout.selectWarehouse}</Button>
                                    </center>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={3} className='pe-3 ps-3 bg-light'>
                            <h4>{LanguageProvider.language.checkout.recap}</h4>
                            <table className='table'>
                                <tbody>
                                    {summary.orderProducts.map((product, i) => {
                                        if (product.product.mainImage !== null) {
                                            var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + product.product.mainImage.id
                                        }
                                        else {
                                            var mainImage = 'https://kateihome.com/defaults/default.png'
                                        }
                                        return (
                                            <tr key={i}>
                                                <td><img src={mainImage} height={40} /></td>
                                                <td>
                                                    {product.product.name} ({product.quantity})<br />
                                                    {product.product.discountedPrice !== null ?
                                                        (
                                                            <>{product.product.discountedPrice} €</>
                                                        ) : (
                                                            <>{product.product.price} €</>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalProducts}</td>
                                        <td className='text-end'>{summary.totals.products} €</td>
                                    </tr>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalShipping}</td>
                                        <td className='text-end'>- €</td>
                                    </tr>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalOrder}</td>
                                        <td className='text-end'>{summary.totals.total} €</td>
                                    </tr>
                                </tfoot>
                            </table>

                            <Alert variant="info" className='mt-3 text-start '>
                                <p className='d-flex align-items-center mt-0 fw-bold'>
                                    <FontAwesomeIcon size={'sm'} icon="triangle-exclamation" color="#842029" className='me-2' /> {LanguageProvider.language.defaults.alert}
                                </p>
                                {LanguageProvider.language.checkout.message}
                                <br/>
                                <Alert.Link href="/terms">{LanguageProvider.language.defaults.terms}</Alert.Link>
                            </Alert>
                        </Col>
                    </Row>
                    
                </Container>

                <ModalAdd entity={modalAddEntity} tableConfig={modalAddProp} data={modalAddData} rowAdd={modalAddRow} closeModalAdd={closeModalAdd} saveModalAdd={saveModalAdd} />
            </>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};