import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Image, Alert, Table, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { updateItem, insertItem, deleteItem, propertiesItem, getItems, searchItems } from '../../core/api';
import ModalDelete from '../../components/ModalDelete';
import { settings } from '../../core/settings';


export default function Wishlist() {

    const navigate = useNavigate();
    
    //config
    const entity = "wishlist_product"
    const searchFields = ["id", "code"]
    const limit = 100
    const page = 1
    const sorting = { "field": "id", "order": "desc" }
    const defaultLabel = 'code'
    const [tableConfig, setTableConfig] = useState(null)

    //modals content
    const [modalDeleteData, setModalDeleteData] = useState(null)

    //data
    const [data, setData] = useState(null)


    useEffect(() => {
        let sid = localStorage.getItem('sid');
        if (sid !== null) {
            getProperties()
            refreshData()
        }
        else {
            navigate('/login')
        }
        
    }, [page, limit]);


    async function getProperties() {
        let data = await propertiesItem(entity)
        if (data.result === 'success') {
            setTableConfig(data.data.data);
        }
    }
    function refreshData() {
        getData(page, limit)
    }
    async function getData(page, limit) {
        let data = await getItems(page, limit, entity, searchFields, sorting)
        console.log(data)
        if (data.result === 'success') {
            setData(data.data.data);
        }
    }



    /* modals */
    /* remove */
    function remove(i) {
        let item = data[i]
        setModalDeleteData(item)
    }
    function closeModalDelete() {
        setModalDeleteData(null)
    }
    async function confirmModalDelete(id) {
        let result = await deleteItem(id, entity)
        if (result === 'success') {
            closeModalDelete()
            refreshData()
        }
    }


    if (data !== null) {
        return (
            <Container fluid="md" className="container-lg mt-3 mb-5">

                <h2>{LanguageProvider.language.profile.wishlist}</h2>

                <Link to='/profile'>
                    <Button className='mb-3' size={'sm'} variant="outline-secondary">
                        <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                    </Button>
                </Link>

                <Card>
                    <Card.Body>
                        {data.length > 0 ? (
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        var product_url = "/product/" + item.idProduct.id
                                        if (item.idProduct.mainImage !== null) {
                                            var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.idProduct.mainImage.id
                                        }
                                        else {
                                            var mainImage = 'https://kateihome.com/defaults/default.png'
                                        }
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <Link to={product_url}>
                                                        <Image src={mainImage} height={80} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    {item.idProduct.code}<br />
                                                    <Link to={product_url}>
                                                        {item.idProduct.name}
                                                    </Link>
                                                    <br />
                                                    {item.idProduct.discountedPrice !== null ?
                                                        (
                                                            <><s>{item.idProduct.price}</s> {item.idProduct.discountedPrice} €</>
                                                        ) : (
                                                            <>{item.idProduct.price} €</>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <span onClick={() => remove(i)} role="button" className='d-flex float-end'><FontAwesomeIcon size={'lg'} icon="trash" /></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <>
                                <Alert variant="warning">
                                    {LanguageProvider.language.defaults.noResults}
                                </Alert>
                            </>
                        )}
                    </Card.Body>
                </Card>

                <ModalDelete defaultLabel={defaultLabel} data={modalDeleteData} closeModalDelete={closeModalDelete} confirmModalDelete={confirmModalDelete} />
            </Container>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};