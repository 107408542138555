import { settings } from './settings';
import { toast } from 'react-toastify';
import { LanguageProvider } from './language';


export async function sendDataToApi(body, action, entity, options) {
    let sid = localStorage.getItem('sid');
    if (entity !== null && typeof (entity) !== 'undefined') {
        var url = settings.apiUrl + '?sid=' + sid + '&action=' + action + '&entity=' + entity;
    }
    else {
        var url = settings.apiUrl + '?sid=' + sid + '&action=' + action;
    }

    var bodyPost = body;
    var actionPost = action;
    var entityPost = entity;
    var optionsPost = options;

    return fetch(url, {
        method: 'POST',
        /*
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        */
        body: JSON.stringify(body),
    }).then((response) => response.json()).then(async (responseData) => {
        if (responseData.result === "success") {
            return responseData;
        }
        else {
            console.log(responseData.error) // VPKey, message
            if (responseData.error.VPKey === 'error.generic.SESSION') {
                var responseRefresh = await refreshSid()

                if (responseRefresh.result === 'success') {
                    return sendDataToApi(bodyPost, actionPost, entityPost, optionsPost)
                }
            }
            else {
                return responseData;
            }
        }
    }).catch((error) => {
        console.error(error);
    });
}

async function refreshSid() {
    let ltsid = localStorage.getItem('ltsid');
    var body = {
        ltsid: ltsid
    };
    let response = await sendDataToApi(body, 'login')
    if (response.result === 'success') {
        if (typeof window !== "undefined") {
            localStorage.setItem('sid', response.data.sid);
            localStorage.setItem('ltsid', response.data.ltsid);
            localStorage.setItem('userInfo', JSON.stringify(response.data.userInfo));
        }
    }
    else {
        localStorage.removeItem('sid');
        localStorage.removeItem('ltsid');
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
    }
    return response;
}

export async function propertiesItem(entity) {
    var body = {
        "subject": entity,
        "constraints": {},
        "options": {
            "pageResultsCount": null
        },
        "sorting": {
            "field": "id",
            "order": "desc"
        }
    };
    const data = await sendDataToApi(body, 'search', 'properties')
    if (data.result === 'success') {
        console.log(data)
    }
    else {
        toast.error(data.error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return data
}

export async function getItem(id, entity, searchFields, sorting) {
    var body = {
        "query": "",
        "fields": searchFields,
        "page": 0,
        "constraints": {
            "id": {
                "type": "value",
                "value": id
            }
        },
        "sorting": sorting,
        "options": {
            "pageResultsCount": 1
        }
    };
    const data = await sendDataToApi(body, 'search', entity)
    if (data.result === 'success') {

    }
    else {
        toast.warn(data.error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return data
}

export async function updateItem(id, data, entity) {
    let body = data
    body.id = id
    const response = await sendDataToApi(body, 'update', entity)
    if (response.result === 'success') {
        toast.success(LanguageProvider.language.defaults.success, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    else {
        toast.warn(response.error.VPKey, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return response.result
}

export async function insertItem(data, entity) {
    let body = data;
    const response = await sendDataToApi(body, 'create', entity)
    if (response.result === 'success') {
        toast.success(LanguageProvider.language.defaults.success, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    else {
        toast.warn(response.error.VPKey, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return response.result
}

export async function deleteItem(id, entity) {
    var body = {}
    body.id = id
    const response = await sendDataToApi(body, 'remove', entity)
    if (response.result === 'success') {
        toast.success(LanguageProvider.language.defaults.success, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    else {
        toast.warn(response.error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return response.result
}

export async function getItems(page, limit, entity, searchFields, sorting) {
    var body = {
        "query": "",
        "fields": searchFields,
        "page": page - 1,
        "constraints": {},
        "sorting": sorting,
        "options": {
            "pageResultsCount": limit
        }
    };
    const data = await sendDataToApi(body, 'search', entity)
    if (data.result === 'success') {

    }
    else {
        toast.warn(data.error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return data
}

export async function searchItems(page, limit, search, entity, searchFields, sorting) {
    var body = {
        "query": search,
        "fields": searchFields,
        "page": page - 1,
        "constraints": {},
        "sorting": sorting,
        "options": {
            "pageResultsCount": limit
        }
    };
    const data = await sendDataToApi(body, 'search', entity)
    if (data.result === 'success') {

    }
    else {
        toast.warn(data.error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return data
}