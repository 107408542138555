import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Container, Image, Alert, Spinner } from 'react-bootstrap';
import { LanguageProvider } from '../core/language';
import { settings } from '../core/settings';
import { sendDataToApi } from '../core/api';
import SearchStandard from '../components/SearchStandard';


export default function Search() {

    const { k } = useParams();
    const id = parseInt(k)
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const keywords = searchParams.get("keywords") || ''
    const [data, setData] = useState(null)


    useEffect(() => {
        getData()
    }, [keywords]);


    async function getData() {
        var body = {
            "queryString": keywords
        }
        
        let data = await sendDataToApi(body, 'search_user_products');
        if (data.result === 'success') {
            if(data.data.data.products)
                setData(data.data.data.products);
        }
        else {
            setData([])
        }
    }

    function changeSearch(t) {
        navigate('/search?keywords='+t)
    }



    if (data !== null && typeof data !== 'undefined') {
        return (
            <>
                <Helmet>
                    <title>Risultati per {keywords} | Katei Home</title>
                    <meta name='description' content={'Risultati ricerca per' + keywords}></meta>
                </Helmet>
                <Container fluid className='p-0 mb-5 mt-3'>
                    <div className='main'>

                        <SearchStandard setSearchParent={changeSearch} />
                        <Container fluid="md" className="container-lg mb-5">
                            <div className='grid--product'>
                                {data.map((item, i) => {
                                    var urlName = item.name.replace(/[\s-]+/g, '-').toLowerCase();
                                    let url = '/product/' + item.id + '-' + urlName;
                                    if (item.mainImage !== null) {
                                        var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                    }
                                    else {
                                        var mainImage = 'https://kateihome.com/defaults/default.png'
                                    }

                                    return (
                                        <div key={i}>
                                            <div className='item--product'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className='product-grid-image-container'>
                                                                    <Link to={url}>
                                                                        <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} height={'auto'} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <Link to={url}>
                                                                    <h3>{item.name}</h3>
                                                                </Link>
                                                                <p className='price'>
                                                                    {item.discountedPrice !== null ?
                                                                        (
                                                                            <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                        ) : (
                                                                            <>{item.price} €</>
                                                                        )
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Container>
                    </div>
                </Container>
            </>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};