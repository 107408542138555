import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from "react-bootstrap";
import { LanguageProvider, translateField } from '../../core/language';
import { formCheck } from '../../core/formCheck';


export default function ModalEdit({ data, rowEdit, closeModalEdit, tableConfig, saveModalEdit, entity }) {

    let newData = returnNewArray({}, rowEdit)
    const [editedData, setEditedData] = useState(newData)
    const [id, setId] = useState(null)
    const [statusModalEdit, setStatusModalEdit] = useState(false)
    const [validated, setValidated] = useState(false);


    useEffect(() => {
        if (data === null) {
            setStatusModalEdit(false)
        }
        else {
            setStatusModalEdit(true)

            setId(data.id)
            if (Object.keys(data).length) {
                let newData = returnNewArray(data, rowEdit)
                setEditedData(newData)
            }
            if(data.businessName && data.businessName.length > 0) {
                setAddressType('1')
            }
            else {
                setAddressType('0')
            }
        }
    }, [data]);


    function returnNewArray(data, row) {
        let newObj = {}
        for (let i = 0; i < row.length; i++) {
            let key = row[i]
            newObj[key] = (data[key] !== null && typeof (data[key]) !== 'undefined') ? data[key] : "";
        }
        return newObj
    }

    function setChange(key, value) {
        setEditedData((prevState) => ({
            ...prevState,
            [key]: value
        }));
    }

    function saveModalEditFun() {
        const form = document.getElementById("form-compiler")
        setValidated(true);
        
        if (form.checkValidity() === false) {
            console.log('form is not valid')
        }
        else if (formCheck(editedData)) {
            console.log('form is valid')
            saveModalEdit(id, editedData)
        }
        else {
            
        }
    }



    const [addressType, setAddressType] = useState('0')
    const companyFieldsExcluded = ['name', 'surname', 'taxCode']
    const privateFieldsExcluded = ['businessName', 'vatNumber', 'sdi', 'pec']


    if (tableConfig !== null && data !== null) {
        return (
            <>
                <Modal show={statusModalEdit} onHide={closeModalEdit} size="lg" scrollable={true} enforceFocus={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.language.defaults.edit}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <label>Tipo indirizzo:</label>
                        <Form.Select onChange={(event) => setAddressType(event.target.value)} value={addressType} id={addressType} className="mb-4">
                            <option value='0'>PRIVATO</option>
                            <option value='1'>AZIENDA</option>
                        </Form.Select>

                        <Form id='form-compiler' noValidate validated={validated} onSubmit={saveModalEditFun}>
                            <Table striped bordered>
                                <tbody>
                                    {Object.keys(editedData).length > 0 ? (
                                        <>
                                            {rowEdit.map((key, i) => {
                                                var validation = tableConfig.fields[key].validation
                                                if((addressType === '0' && !privateFieldsExcluded.includes(key)) || (addressType === '1' && !companyFieldsExcluded.includes(key))) {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{LanguageProvider.language.labels[key]} {validation.required && (<>*</>)}</td>
                                                            <td>
                                                                {/** i vari casi */}
                                                                {(() => {
                                                                    var type = tableConfig.fields[key].type;
                                                                    switch (type) {
                                                                        case 'text': return (
                                                                            <Form.Control type="text" required={validation.required} value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                        case 'password': return (
                                                                            <Form.Control type="password" value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                        case 'date': return (
                                                                            <Form.Control type="date" value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                        case 'number': return (
                                                                            <Form.Control type="number" value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                        case 'color': return (
                                                                            <Form.Control type="color" className="form-control-color" value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                        case 'boolean': return (
                                                                            <Form.Select onChange={(event) => setChange(key, event.target.value)} value={editedData[key]}>
                                                                                <option value={0}>{LanguageProvider.language.defaults.no}</option>
                                                                                <option value={1}>{LanguageProvider.language.defaults.yes}</option>
                                                                            </Form.Select>
                                                                        );
                                                                        case 'option': {
                                                                            let options = tableConfig.fields[key].options.options;
                                                                            return (
                                                                                <Form.Select onChange={(event) => setChange(key, event.target.value)} value={editedData[key]}>
                                                                                    {options.map((option, i) => {
                                                                                        return (
                                                                                            <option key={i} value={option.value}>{translateField(option.value, key)}</option>
                                                                                        )
                                                                                    })}
                                                                                </Form.Select>
                                                                            )
                                                                        };
                                                                        default: return (
                                                                            <Form.Control type="text" required={validation.required} value={editedData[key]} onChange={(event) => setChange(key, event.target.value)} />
                                                                        );
                                                                    }
                                                                })()}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                            </Table>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={saveModalEditFun}>
                            {LanguageProvider.language.defaults.save}
                        </Button>
                        <Button variant="secondary" onClick={closeModalEdit}>
                            {LanguageProvider.language.defaults.close}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
};