import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Pagination, Image } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';
import { settings } from '../../core/settings';
import { sendDataToApi } from '../../core/api';
import moment from 'moment';


export default function Blog() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [entity] = useState("get_public_news")
    const [searchFields] = useState(["id", "title", "metadescription", "metatitle"])
    const [sorting, setSorting] = useState({ "field": "id", "order": "desc" })
    const [totalPages, setTotalPages] = useState(1)
    const [limit, setLimit] = useState(12)
    //data
    const [data, setData] = useState(null)
    //search
    const [search, setSearch] = useState('')


    useEffect(() => {
        init()
    }, [page]);

    
    async function init() {
        getData(1, 10)
    }

    async function getData(page, limit) {
        var body = {
            "query": "",
            "fields": searchFields,
            "page": page - 1,
            "constraints": {},
            "sorting": sorting,
            "options": {
                "pageResultsCount": limit
            }
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log(data.data.data)
            setData(data.data.data);
            setTotalPages(data.data.pagesCount)
        }
        else {
            console.log(data)
            setData([])
        }
    }

    function changePage(p) {
        navigate('/blog/' + p)
    }

    function goToPrevPage() {
        if(page > 1) {
            changePage(page-1)
        }
    }
    function goToNextPage() {
        if(page < totalPages) {
            changePage(page+1)
        }
    }
    function goToFirstPage() {
        if(page !== 1) {
            changePage(1)
        }
    }
    function goToLastPage() {
        if(page !== totalPages) {
            changePage(totalPages)
        }
    }

    function openEntry(item) {
        navigate('/entry/' + item.id)
    }



    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>{'Blog - ' + page}</title>
                </Helmet>

                <Container fluid className='p-0'>
                    <div className='blog-container'>
                        {data.length > 0 ? (
                            <div className='blog--grid'>
                                {data.map((item, i) => {
                                    let date = moment(item.date).format('DD/MM/YYYY');
                                    let friendly_url = "/entry/" + item.id + "-" + item.friendlyurl;
                                    if (item.image !== null) {
                                        var image = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.image
                                    }
                                    else {
                                        var image = 'https://kateihome.com/defaults/default.png'
                                    }
                                    if (item.tags !== null && item.tags !== '') {
                                        var tags = item.tags.split(',');
                                    }
                                    else {
                                        var tags = [];
                                    }
                                    return (
                                        <div key={i} className='entry'>
                                            <table className='entry'>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <div className='preview'>
                                                                {image !== null ? (
                                                                    <Link to={friendly_url}>
                                                                        <Image src={image} alt={item.title} height={400} width={600} />
                                                                    </Link>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className='tags'>
                                                                {tags.map((tag, j) => {
                                                                    let tagTrim = tag.trim()
                                                                    return (
                                                                        <span key={j}>{tagTrim}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                            <h3><Link to={friendly_url}>{item.title}</Link></h3>
                                                            <p className='date'>{date}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <>
                                <div className='alert warning'>
                                    {LanguageProvider.language.defaults.noResults}
                                </div>
                            </>
                        )}

                    

                        <div className="d-flex justify-content-center mt-3 mb-5">
                            <Pagination>
                                <Pagination.First className={page == 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                                <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                                <Pagination.Item>
                                    {page}
                                </Pagination.Item>
                                <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                                <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                            </Pagination>
                        </div>

                    </div>
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};