import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Container, Alert, Button } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';


export default function Status({refreshUserInfo}) {


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const status = searchParams.get("status") || ''
    const order = searchParams.get("order") || ''
    const transaction = searchParams.get("transaction") || ''
    

    useEffect(() => {
        refreshUserInfo()
    }, []);


    function goToOrders() {
        navigate('/orders')
    }

    function contactHelp() {
        navigate('/contact')
    }


    return (
        <>
            <Container fluid="md" className="container-lg mt-5 mb-5">

                    {status === 'true' ? (
                        <Alert variant="success">
                            <Alert.Heading>Grazie per il tuo ordine!</Alert.Heading>
                            <p>
                            Complimenti! Hai concluso il tuo ordine. Tieni monitorata la tua mail per rimanere aggiornato.
                            </p>
                            <hr />
                            <p className="mb-0">
                            Id transazione: {transaction}<br/>
                            Id ordine: {order}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => goToOrders()} variant="outline-success">
                                    Vai ai tuoi ordini
                                </Button>
                            </div>
                        </Alert>
                    ) : (
                        <Alert variant="danger" >
                            <Alert.Heading>Errore!</Alert.Heading>
                            <p>
                            Purtroppo si è verificato un errore! Contatta l'assistenza che risolverà il problema al più presto.<br/>
                            help@kateihome.com.
                            </p>
                            <hr />
                            <p className="mb-0">
                            Id transazione: {transaction}<br/>
                            Id ordine: {order}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => contactHelp()} variant="outline-danger">
                                    Contatta l'assistenza
                                </Button>
                            </div>
                        </Alert>
                    )}
                            
            </Container>

        </>
    );
   
};