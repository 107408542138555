import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LanguageProvider } from '../core/language';
import { Container, Carousel, Image } from 'react-bootstrap';
import { settings } from '../core/settings';
import { sendDataToApi } from '../core/api';
import slider1 from '../assets/images/slider_1.png'
import slider2 from '../assets/images/slider_2.png'
import slider3 from '../assets/images/slider_3.png'
import banner from '../assets/images/banner-homepage.jpg'

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


export default function Home() {

    const [entity] = useState("get_user_manufacturers")
    const [brands, setBrands] = useState(null)
    const [mostSold, setMostSold] = useState([])
    const [lastProducts, setLastProducts] = useState([])


    useEffect(() => {
        getBrands()
        getLastProducts()
        getMostSold()
    }, []);


    async function getBrands() {
        var body = {};
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            setBrands(data.data.data);
        }
    }

    async function getMostSold() {
        var body = {};
        let data = await sendDataToApi(body, 'get_user_most_sold_products');
        if (data.result === 'success') {
            setMostSold(data.data.data);
        }
    }

    async function getLastProducts() {
        var body = {};
        let data = await sendDataToApi(body, 'get_user_last_products');
        if (data.result === 'success') {
            setLastProducts(data.data.data);
        }
    }



    return (
        <>
            <Container fluid className='p-0'>
                <div className='main'>
                    <Carousel>
                        <Carousel.Item>
                            <Image src={slider1} width={'100%'} />
                            <Carousel.Caption>

                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={slider2} width={'100%'}  />
                            <Carousel.Caption>

                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={slider3} width={'100%'}  />
                            <Carousel.Caption>

                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>



                    <Container fluid="md" className='mb-5' style={{ 'marginTop': '70px' }}>
                        <center>
                            <h2>{LanguageProvider.language.home.last}</h2>
                        </center>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={false}
                            grabCursor={true}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {lastProducts.map((item, i) => {
                                let url = '/product/' + item.id;
                                if (item.mainImage !== null) {
                                    var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                }
                                else {
                                    var mainImage = 'https://kateihome.com/defaults/default.png'
                                }

                                return (
                                    <SwiperSlide key={i} className='mb-3'>
                                        <div className='item--product'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className='product-grid-image-container'>
                                                                <Link to={url}>
                                                                    <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} height={'auto'} />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <Link to={url}>
                                                                <h3>{item.name}</h3>
                                                            </Link>
                                                            <p className='price'>
                                                                {item.discountedPrice !== null ?
                                                                    (
                                                                        <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                    ) : (
                                                                        <>{item.price} €</>
                                                                    )
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Container>

                    <Container fluid="md" className='mb-5' style={{ 'marginTop': '70px' }}>
                        <center>
                            <h2>{LanguageProvider.language.home.sell}</h2>
                        </center>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={false}
                            grabCursor={true}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {mostSold.map((item, i) => {
                                let url = '/product/' + item.id;
                                if (item.mainImage !== null) {
                                    var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                }
                                else {
                                    var mainImage = 'https://kateihome.com/defaults/default.png'
                                }

                                return (
                                    <SwiperSlide key={i} className='mb-3'>
                                        <div className='item--product'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className='product-grid-image-container'>
                                                                <Link to={url}>
                                                                    <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} height={'auto'} />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <Link to={url}>
                                                                <h3>{item.name}</h3>
                                                            </Link>
                                                            <p className='price'>
                                                                {item.discountedPrice !== null ?
                                                                    (
                                                                        <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                    ) : (
                                                                        <>{item.price} €</>
                                                                    )
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Container>

                    <Container fluid className='p-0 mb-5' style={{ 'marginTop': '70px' }}>
                        <div className='slide-category-container' style={{ "backgroundImage": `url('${banner}')` }}>
                            <div className='slide--category'>
                                <h1>IL BELLO DI ANDARE IN BIANCO.<br />IN CUCINA</h1>
                            </div>
                        </div>
                    </Container>

                    <Container fluid="md" className='mb-5' style={{ 'marginTop': '70px' }}>
                        <center>
                            <h2>{LanguageProvider.language.home.brand}</h2>
                        </center>
                        <div className='grid--brand'>
                            {brands !== null &&
                                <>
                                    {brands.map((item, i) => {
                                        var url = '/brand/' + item.id;
                                        if (item.previewImagePath !== null) {
                                            var previewImage = settings.domain + '/backoffice/app/' + item.previewImagePath
                                        }
                                        else {
                                            var previewImage = 'https://kateihome.com/defaults/default.png'
                                        }
                                        return (
                                            <div key={i}>
                                                <div className='item--brand'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <td>
                                                                    <Link to={url}>
                                                                        <Image placeholder='empty' src={previewImage} alt={item.name} height={'auto'} width={'95%'} />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </Container>

                </div>
            </Container>
        </>
    );
};