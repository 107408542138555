import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Form, FloatingLabel, Button, Alert } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';
import { toast, ToastContainer } from 'react-toastify';
import { Utils } from '../../core/utils';


export default function Recover() {

    const navigate = useNavigate();

    const [key, setKey] = useState('');
    const [password, setPassword] = useState('');

    async function handleSubmit(e) {
        e.preventDefault();
        var body = {
            key: key,
            password: Utils.encryptPassword(password),
        };
        const response = await sendDataToApi(body, 'verify_reset_password', null)

        console.log(response)
        if (response.result === 'success') {
            toast.success('Password updated.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate('/login')
        }
        else {
            toast.error(response.error.VPKey, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    return (
        <Container fluid="md" className="container-lg mt-5 mb-5">
            <div className="col-md-6">
                <Form onSubmit={handleSubmit}>
                    <div className="mb-3 mt-2">
                        <h3>{LanguageProvider.language.recover.title}</h3>
                        <p>{LanguageProvider.language.recover.insertKey}</p>
                    </div>

                    <FloatingLabel label={LanguageProvider.language.recover.key} className="mb-3">
                        <Form.Control type="text" placeholder="key" onChange={e => setKey(e.target.value)} />
                    </FloatingLabel>

                    <FloatingLabel label={LanguageProvider.language.login.password} className="mb-3">
                        <Form.Control type="password" placeholder="password" onChange={e => setPassword(e.target.value)} />
                    </FloatingLabel>

                    <Button variant="primary" size="lg" type="submit">{LanguageProvider.language.recover.confirm}</Button>
                </Form>
            </div>
            <ToastContainer />
        </Container>
    );
};