import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Breadcrumb, Image, Spinner, Alert } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';


export default function Categories() {

    const { k } = useParams();
    const id = parseInt(k)

    const [entity] = useState("get_user_categories")
    const [data, setData] = useState(null)
    const [info, setInfo] = useState(null)
    const [categoryName, setCategoryName] = useState(null)


    useEffect(() => {
        if (typeof id !== 'undefined') {
            getData(id)
        }
    }, [id]);


    async function getData(id) {
        var body = {
            "getAlsoEmptyCategories": true,
            "idProductCategoryParent": id,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log('categories', data.data.data)
            setData(data.data.data.categories);
            setInfo(data.data.data.info);
            setCategoryName(data.data.data.info.parentProductsCategories[data.data.data.info.parentProductsCategories.length - 1].name)
        }
        else {
            console.log(data)
            setData(null)
        }
    }



    if (data !== null && typeof data !== 'undefined') {
        return (
            <>
                <Helmet>
                    <title>{categoryName} | Katei Home</title>
                    <meta name='description' content={"Scopri tutti i prodotti nella categoria: " + categoryName}></meta>
                </Helmet>
                <Container fluid="md" className="container-lg mt-5 mb-5 bg-light p-2 ps-4 pe-4">
                    <Breadcrumb className='mt-3'>
 
                            <Breadcrumb.Item linkProps={{ to: '/products' }} linkAs={Link}>
                                {LanguageProvider.language.header.products}
                            </Breadcrumb.Item>

                        {info.parentProductsCategories.map((item, i) => {
                            var urlName = item.name.replace(/[\s-]+/g, '-').toLowerCase();
                            var url = '/categories/' + item.id + '-' + urlName;
                            return (
                                <Breadcrumb.Item key={i} linkProps={{ to: url }} linkAs={Link}>
                                    {item.name}
                                </Breadcrumb.Item>
                            )
                        })}
                    </Breadcrumb>
                </Container>

                <Container fluid="md" className="container-lg mb-5">
                    <div className='main'>

                        <div className='grid--category'>
                            {data.map((item, i) => {
                                var urlName = item.category.name.replace(/[\s-]+/g, '-').toLowerCase();
                                if (item.info.hasChild === true) {
                                    var url = '/categories/' + item.category.id + '-' + urlName;
                                }
                                else {
                                    var url = '/category/' + item.category.id + '-' + urlName;
                                }
                                if (item.category.previewImagePath !== null) {
                                    var previewImage = item.category.previewImagePath
                                }
                                else {
                                    var previewImage = 'https://kateihome.com/defaults/default.png'
                                }
                                return (
                                    <div key={i}>
                                        <div className='item--category'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <Link to={url}>
                                                                <Image placeholder='empty' src={previewImage} alt={item.category.name} width={'100%'} height='250' style={{objectFit: 'cover'}}  />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Link to={url}>
                                                                <h3>{item.category.name}</h3>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div style={{ 'textAlign': 'right' }}>
                                                                <Link to={url}>
                                                                    <Button variant="light">{LanguageProvider.language.defaults.more}</Button>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};