import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Image, Spinner } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';
import { settings } from '../../core/settings';
import { sendDataToApi } from '../../core/api';


export default function Brand() {

    const [errors, setErrors] = useState('');
    const { k } = useParams();
    const id = parseInt(k)

    const [entity] = useState("get_user_manufacturer_products")
    const [data, setData] = useState(null)
    const [brandName, setBrandName] = useState(null)
    const [manufacturer, setManufacturer] = useState(null)


    useEffect(() => {
        if (typeof id !== 'undefined') {
            getData(id)
        }
    }, [id]);


    async function getData(id) {
        var body = {
            "id": id
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log(data.data.data)
            setData(data.data.data.products);
            setManufacturer(data.data.data.manufacturer)
            setBrandName(data.data.data.manufacturer.name)
        }
        else {
            console.log(data)
            setData(null)
        }
    }




    if (data !== null && typeof data !== 'undefined') {
        return (
            <>
                <Helmet>
                    <title>{brandName} | Katei Home</title>
                    <meta name='description' content={brandName}></meta>
                </Helmet>
                <Container fluid className='p-0 mb-5 mt-3'>
                    <div className='main'>

                        <Container fluid="md" className="container-lg mb-5">
                            <Alert variant="light">
                                <Alert.Heading>{manufacturer.name}</Alert.Heading>
                                <p>
                                    {manufacturer.vatNumber}<br />
                                    {manufacturer.address}, {manufacturer.city}, {manufacturer.postalCode}<br />
                                    {manufacturer.phoneNumber}<br />
                                    {manufacturer.email}
                                </p>
                            </Alert>

                            <div className='grid--product'>
                                {data.map((item, i) => {
                                    let url = '/product/' + item.id;
                                    if (item.mainImage !== null) {
                                        var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                    }
                                    else {
                                        var mainImage = 'https://kateihome.com/defaults/default.png'
                                    }

                                    return (
                                        <div key={i}>
                                            <div className='item--product'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className='product-grid-image-container'>
                                                                    <Link to={url}>
                                                                        <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} height={'auto'} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <Link to={url}>
                                                                    <h3>{item.name}</h3>
                                                                </Link>
                                                                <p className='price'>
                                                                    {item.discountedPrice !== null ?
                                                                        (
                                                                            <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                        ) : (
                                                                            <>{item.price} €</>
                                                                        )
                                                                    }
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Container>
                    </div>
                </Container>
            </>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};