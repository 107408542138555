import React, { useState, useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';
import { toast, ToastContainer } from 'react-toastify';
import { Utils } from '../../core/utils';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";


export default function LoginRegister({ refreshUserInfo }) {

    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    //login
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState('password');


    function handleSubmitLoginForm() {
        var body = {
            remember: true,
            password: Utils.encryptPassword(password),
            username: username
        };
        handleSubmitLogin(body)
    }

    async function handleSubmitLogin(body) {
        const response = await sendDataToApi(body, 'login', null)
        console.log(response)
        if (response.result === 'success') {
            var userData = response.data;
            if (typeof window !== "undefined") {
                localStorage.setItem('sid', userData.sid);
                localStorage.setItem('ltsid', userData.ltsid);
                localStorage.setItem('userInfo', JSON.stringify(userData.userInfo));
                refreshUserInfo()
                navigate('/profile')
            }
        }
        else {
            toast.error(LanguageProvider.language.login.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    function togglePassword() {
        if (passwordType === 'password') {
            setPasswordType('text')
        }
        else {
            setPasswordType('password')
        }
    }




    //register
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [passwordR, setPasswordR] = useState('');

    async function handleSumitRegisterForm() {
        await executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
            console.log(gReCaptchaToken, "response Google reCaptcha server");
            var body = {
                name: name,
                surname: surname,
                password: Utils.encryptPassword(passwordR),
                email: email,
                'g-recaptcha-response': gReCaptchaToken
            };
            handleSubmitRegister(body);
        });
    }
    async function handleSubmitRegister(body) {
        const response = await sendDataToApi(body, 'public_create_user', null)

        if (response.result === 'success') {
            var userData = response.data;
            toast.success(LanguageProvider.language.register.success, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            //navigate('/login')
            //autologin
            var b = {
                remember: true,
                password: body.password,
                username: body.email
            };
            handleSubmitLogin(b)
        }
        else {
            toast.error(LanguageProvider.language.register.fail, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }



    return (
        <Container fluid="md" className="mt-5 mb-5">
            <Row>
                <Col lg="6" md="12" className="p-4">
                    <div className="mb-3 mt-2">
                        <h3>{LanguageProvider.language.login.title}</h3>
                    </div>


                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.login.username}</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="name@example.com"
                            onChange={e => setUserName(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.login.password}</InputGroup.Text>
                        <Form.Control
                            type={passwordType}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <InputGroup.Text onClick={togglePassword}><FontAwesomeIcon size={'sm'} icon="eye" /></InputGroup.Text>
                    </InputGroup>

                    <div className="mb-3 mt-2">
                        <Form.Check type="checkbox" label={LanguageProvider.language.login.remember} />
                    </div>

                    <Button onClick={() => handleSubmitLoginForm()} variant="outline-primary" size="lg" type="submit">{LanguageProvider.language.login.login}</Button>

                    <Alert variant={'warning'} className='mt-3'>
                        {LanguageProvider.language.login.recover}{' '}<b><Alert.Link href='/recover'>{LanguageProvider.language.login.click}</Alert.Link></b>
                    </Alert>
                </Col>
                <Col lg="6" md="12" className="p-4">
                    <div className="mb-3 mt-2">
                        <h3>{LanguageProvider.language.register.title}</h3>
                    </div>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.register.name}</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e => setName(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.register.surname}</InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={e => setSurname(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.register.email}</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="name@example.com"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text className='w-25'>{LanguageProvider.language.login.password}</InputGroup.Text>
                        <Form.Control
                            type={passwordType}
                            onChange={e => setPasswordR(e.target.value)}
                        />
                        <InputGroup.Text onClick={togglePassword}><FontAwesomeIcon size={'sm'} icon="eye" /></InputGroup.Text>
                    </InputGroup>

                    <Button onClick={() => handleSumitRegisterForm()} variant="outline-primary" size="lg" type="submit">{LanguageProvider.language.register.register}</Button>

                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};