import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Spinner, Alert, Image } from 'react-bootstrap';
import { LanguageProvider } from '../core/language';
import { sendDataToApi } from '../core/api';
import { settings } from '../core/settings';


export default function Brands() {

    const [entity] = useState("get_user_manufacturers")
    const [data, setData] = useState(null)


    useEffect(() => {
        getData()
    }, []);


    async function getData() {
        var body = {};
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log(data.data.data)
            setData(data.data.data);
        }
        else {
            console.log(data)
            //setData([])
        }
    }

    if (data !== null && typeof data !== 'undefined') {
        return (
            <Container fluid className='p-0'>
                <div className='main'>
                    <div className='grid--brand'>
                        {data.map((item, i) => {
                            var url = '/brand/' + item.id;
                            if (item.previewImagePath !== null) {
                                //var previewImage = item.previewImagePath
                                var previewImage = settings.domain + '/backoffice/app/' + item.previewImagePath
                            }
                            else {
                                var previewImage = 'https://kateihome.com/defaults/default.png'
                            }
                            return (
                                <div key={i}>
                                    <div className='item--brand'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <Link to={url}>
                                                            <Image src={previewImage} alt={item.name} height={300} width={300} />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Container>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};