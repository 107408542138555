import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Button } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';


export default function Logout({resetUserInfo}) {

    const navigate = useNavigate();

    useEffect(() => {

    }, []);


    const fetchLogout = async () => {
        let body = {}
        const response = await sendDataToApi(body, 'logout', null)
        resetUserInfo()
        localStorage.removeItem('sid');
        localStorage.removeItem('ltsid');
        localStorage.removeItem('userInfo');
        navigate('/login')
    }


    return (
        <Container fluid="md" className="container-lg mt-3 mb-5">

            <Alert variant="danger">
                <Alert.Heading>{LanguageProvider.language.logout.title}</Alert.Heading>
                <p>{LanguageProvider.language.logout.subtitle}</p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => fetchLogout()} variant="outline-danger">{LanguageProvider.language.profile.logout}</Button>
                </div>
            </Alert>

        </Container>
    );
};