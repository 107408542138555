import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageProvider } from '../../core/language';
import logo from '../../assets/logo-bianco-katei.png';
import ita from '../../assets/ita.png';
import eng from '../../assets/eng.png';


export default function FooterBlock() {


    function changeLanguage(lan) {
        LanguageProvider.changeLanguage(lan)
    }


    return (
        <footer>
            <div className='footer-grid'>
                <div className='footer-block'>
                    <div className='footer-logo'>
                        <Link to="/">
                            <Image src={logo} alt='Logo of the site' width={50} height={50} />
                        </Link>
                        <p className='small'>Quality in your name</p>
                    </div>
                </div>
                <div className='footer-block'>
                    <h6>{LanguageProvider.language.footer.title1}</h6>
                    <ul>
                        <li>
                            <Link to="/about">
                                {LanguageProvider.language.header.about}
                            </Link>
                        </li>
                        <li>
                            <Link to="/brands">
                                {LanguageProvider.language.header.brand}
                            </Link>
                        </li>
                        <li>
                            <Link to="/products">
                                {LanguageProvider.language.header.products}
                            </Link>
                        </li>
                        <li>
                            <Link to="/blog/1">
                                {LanguageProvider.language.header.news}
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact">
                                {LanguageProvider.language.header.contact}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='footer-block'>
                    <h6>{LanguageProvider.language.footer.title3}</h6>
                    <p><a target='_blank' href='https://www.facebook.com/KateiQualityInYourName/'>Facebook</a></p>
                    <p><a target='_blank' href='https://www.instagram.com/katei_group/'>Instagram</a></p>
                    <p><a target='_blank' href='https://vimeo.com/user94816823'>Vimeo</a></p>
                </div>
                <div className='footer-block'>
                    <h6>{LanguageProvider.language.footer.title8}</h6>
                    <ul>
                        <li>
                            <Link to="/terms">
                                {LanguageProvider.language.defaults.terms}
                            </Link>
                        </li>
                        <li>
                            <a href="https://www.iubenda.com/privacy-policy/64409154" target='_blank'>
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.iubenda.com/privacy-policy/64409154/cookie-policy" target='_blank'>
                                Cookie Policy
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='footer-block'>
                    <h6>{LanguageProvider.language.footer.title4}</h6>

                    <h6>{LanguageProvider.language.footer.title5}</h6>
                    <p><a href='mailto:supporto@kateihome.com'>supporto@kateihome.com</a></p>
                    <h6>{LanguageProvider.language.footer.title6}</h6>
                    <p><a href='mailto:ordini@kateihome.com'>ordini@kateihome.com</a></p>
                </div>
            </div>
            <hr />
            <p className='small'>
                <b>© Katei 2022 - 2023</b>
                <br />
                P.I. e C.F. 01689760930
            </p>
            <p className='small mt-4'>
                <span className='me-2' onClick={() => changeLanguage('ita')}><Image height={'20'} src={ita} /></span>
                <span onClick={() => changeLanguage('eng')}><Image height={'20'} src={eng} /></span>
            </p>
            <ToastContainer />
        </footer>
    );
};