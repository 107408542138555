import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Alert, Table, Button, InputGroup, Form, Spinner, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { deleteItem, propertiesItem, getItems, sendDataToApi, updateItem } from '../../core/api';
import ModalDelete from '../../components/ModalDelete';
import { settings } from '../../core/settings';


export default function Cart({refreshUserInfo}) {

    const navigate = useNavigate();

    //config
    const entity = "cart_product"
    const searchFields = ["id", "code"]
    const limit = 100
    const page = 1
    const sorting = { "field": "id", "order": "desc" }
    const defaultLabel = 'code'
    const [tableConfig, setTableConfig] = useState(null)

    
    
    //modals content
    const [modalDeleteData, setModalDeleteData] = useState(null)

    //data
    const [data, setData] = useState(null)


    useEffect(() => {
        let sid = localStorage.getItem('sid');
        if (sid !== null) {
            getProperties()
            refreshData()
        }
        else {
            navigate('/login')
        }
        
    }, [page, limit]);


    async function getProperties() {
        let data = await propertiesItem(entity)
        if (data.result === 'success') {
            setTableConfig(data.data.data);
        }
    }
    function refreshData() {
        getData(page, limit)
    }
    async function getData(page, limit) {
        let data = await getItems(page, limit, entity, searchFields, sorting)
        if (data.result === 'success') {
            setData(data.data.data);
            refreshUserInfo()
        }
    }



    /* modals */
    /* remove */
    function remove(i) {
        let item = data[i]
        setModalDeleteData(item)
    }
    function closeModalDelete() {
        setModalDeleteData(null)
    }
    async function confirmModalDelete(id) {
        let result = await deleteItem(id, entity)
        if (result === 'success') {
            closeModalDelete()
            refreshData()
        }
    }


    async function checkout() {
        let data = await sendDataToApi({}, 'init_pending_order')
        console.log(data)
        navigate('/checkout/shipping')
    }

    async function changeQty(item, qty) {
        console.log(item, qty)
        if(qty >= 1) {
            let post = {
                'quantity': qty
            }
            let result = await updateItem(item.id, post, 'cart_product')
            console.log(result)
            if (result === 'success') {
                refreshData()
            }
        }
    }


    if (data !== null) {
        return (
            <Container fluid="md" className="container-lg mt-3 mb-5">

                <h2>{LanguageProvider.language.cart.title}</h2>

                <Card>
                    <Card.Body>
                        {data.length > 0 ? (
                            <>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Prodotti</th>
                                            <th>Qta</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, i) => {
                                            var product_url = "/product/" + item.idProduct.id
                                            if (item.idProduct.mainImage !== null) {
                                                var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.idProduct.mainImage.id
                                            }
                                            else {
                                                var mainImage = 'https://kateihome.com/defaults/default.png'
                                            }
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <Link to={product_url}>
                                                            <Image src={mainImage} height={50} />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={product_url}>
                                                            {item.idProduct.name}
                                                        </Link>
                                                        <br />
                                                        {item.idProduct.discountedPrice !== null ?
                                                            (
                                                                <><s>{item.idProduct.price}</s> {item.idProduct.discountedPrice} €</>
                                                            ) : (
                                                                <>{item.idProduct.price} €</>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        
                                                        <InputGroup size='sm' className="mb-3" style={{ "width": 110 }}>
                                                            <Button size='sm' onClick={() => changeQty(item, item.quantity*1 - 1)} variant="secondary" style={{ 'width': 36 }}>
                                                                -
                                                            </Button>
                                                            <Form.Control
                                                                className='text-center'
                                                                value={item.quantity}
                                                                disabled
                                                            />
                                                            <Button size='sm' onClick={() => changeQty(item, item.quantity*1 + 1)} variant="secondary" style={{ 'width': 36 }}>
                                                                +
                                                            </Button>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <span role="button" onClick={() => remove(i)} className='d-flex float-end'><FontAwesomeIcon size={'lg'} icon="trash" /></span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>

                                <br />
                                <center>
                                    <Button size={'lg'} onClick={() => checkout()} variant="primary">{LanguageProvider.language.cart.goToCheckout}</Button>
                                </center>
                            </>
                        ) : (
                            <>
                                <Alert variant="warning">
                                    {LanguageProvider.language.defaults.noResults}
                                </Alert>
                            </>
                        )}


                        <Alert variant="warning" className='mt-3 text-start '>
                            <p className='d-flex align-items-center mt-0 fw-bold'>
                                <FontAwesomeIcon size={'lg'} icon="triangle-exclamation" color="#842029" className='me-2' /> {LanguageProvider.language.defaults.alert}
                            </p>
                            {LanguageProvider.language.checkout.message}
                            <br/>
                            <Alert.Link href="/terms">{LanguageProvider.language.defaults.terms}</Alert.Link>
                        </Alert>
                    </Card.Body>
                </Card>

                <ModalDelete defaultLabel={defaultLabel} data={modalDeleteData} closeModalDelete={closeModalDelete} confirmModalDelete={confirmModalDelete} />
            </Container>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};