import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Breadcrumb, Form, Row, Col, Image, Offcanvas, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { settings } from '../../core/settings';
import { sendDataToApi, insertItem } from '../../core/api';


export default function Category({refreshUserInfo}) {

    const { k } = useParams();
    const id = parseInt(k)

    const [sid, setSid] = useState(null)
    const [entity] = useState("get_user_category_products")
    const [data, setData] = useState(null)
    const [info, setInfo] = useState(null)
    const [categoryName, setCategoryName] = useState(null)
    const [filters, setFilters] = useState(null)
    const [filtersSearch, setFiltersSearch] = useState({})
    const [fastDelivery, setFastDelivery] = useState(false)


    useEffect(() => {
        if (typeof window !== "undefined") {
            let token = localStorage.getItem('sid')
            setSid(token)
        }
        if (typeof id !== 'undefined') {
            getData(id)
        }
    }, [id]);


    async function getData(id) {
        var body = {
            "idProductCategory": id,
            "onlyAvailable": fastDelivery,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log(data.data.data)
            setData(data.data.data.products);
            setInfo(data.data.data.parentProductsCategories);
            setFilters(data.data.data.filters);
            setCategoryName(data.data.data.parentProductsCategories[data.data.data.parentProductsCategories.length - 1].name)
        }
        else {
            console.log(data)
            setData(null)
        }
    }


    async function applyFilters() {
        console.log(filtersSearch)
        var body = {
            "idProductCategory": id,
            "filters": filtersSearch,
            "onlyAvailable": fastDelivery
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            setData(data.data.data.products);
        }
        else {
            console.log(data)
            setData(null)
        }
    }

    function checkFilters(obj, id, subid) {
        if (id in obj) {
            if (obj[id].includes(subid)) {
                obj[id] = obj[id].filter(item => item !== subid)
            }
            else {
                obj[id].push(subid);
            }
        }
        else {
            obj[id] = [];
            obj[id].push(subid);
        }
        return obj
    }

    function handleChangeFilter(id, subid) {
        let tempObj = checkFilters(filtersSearch, id, subid)
        setFiltersSearch(tempObj)
    }

    async function addToWishlist(idP) {
        let body = {
            'idProduct': idP
        }
        let response = await sendDataToApi(body, 'create', 'wishlist_product')
        if (response.result === 'success') {
            getData(id)
        }
    }
    async function removeFromWishlist(idP) {
        let body = {
            'idProduct': idP
        }
        let response = await sendDataToApi(body, 'remove_wishlist_product')
        if (response.result === 'success') {
            getData(id)
        }
    }
    async function addToCart(idP) {
        let post = {
            'idProduct': idP,
            'quantity': 1
        }
        let result = await insertItem(post, 'cart_product')
        console.log(result)
        if (result === 'success') {
            refreshUserInfo()
        }
    }

    async function changeFastDelivery() {
        var newState = !fastDelivery
        setFastDelivery(newState)
        console.log(filtersSearch)
        var body = {
            "idProductCategory": id,
            "filters": filtersSearch,
            "onlyAvailable": newState,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            setData(data.data.data.products);
        }
        else {
            console.log(data)
            setData(null)
        }
    }


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    if (data !== null && typeof data !== 'undefined') {
        return (
            <>
                <Helmet>
                    <title>{categoryName} | Katei Home</title>
                    <meta name='description' content={"Elenco articoli per: " + categoryName}></meta>
                </Helmet>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{LanguageProvider.language.category.filters}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='column--filters'>

                            <Form>
                                <Form.Check 
                                    type="switch"
                                    onChange={() => changeFastDelivery()}
                                    label={LanguageProvider.language.category.fastDelivery}
                                    checked={fastDelivery}
                                />
                            </Form>
                            {filters.map((item, i) => {
                                return (
                                    <div key={i} className='mt-5'>
                                        <p>
                                            {item.filter.name}
                                        </p>
                                        {item.values.map((subitem, j) => {
                                            return (
                                                <Form.Group key={j} className="mb-3">
                                                    <Form.Check onChange={() => handleChangeFilter(subitem.idProductFilter, subitem.id)} type="checkbox" label={subitem.value} />
                                                </Form.Group>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            <br/><br/>
                        </div>
                        <div className="apply-btn-fixed">
                            <Button onClick={applyFilters} size={'sm'} variant="outline-secondary">{LanguageProvider.language.category.apply}</Button>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <Container fluid className='p-0 mb-5 mt-0'>

                    <div id="chevron-filters" role="button" onClick={handleShow}>
                        <FontAwesomeIcon size={'lg'} icon="chevron-right" color="#666" className='mb-2' />
                        <span className="filters-chevron-text">{LanguageProvider.language.category.filters}</span>
                    </div>

                    <div className='main' id="category-page">
                        <Container fluid="md" className="container-lg mt-5 mb-3 bg-light p-2 ps-4 pe-4 navigation-top">
                            <Breadcrumb className='mt-3'>
                                <Breadcrumb.Item linkProps={{ to: '/products' }} linkAs={Link}>
                                    {LanguageProvider.language.header.products}
                                </Breadcrumb.Item>
                                {info.map((item, i) => {
                                    var urlName = item.name.replace(/[\s-]+/g, '-').toLowerCase();
                                    var url = '/categories/' + item.id + '-' + urlName;
                                    if (i === info.length - 1) {
                                        return (
                                            <Breadcrumb.Item key={i}>
                                                {item.name}
                                            </Breadcrumb.Item>
                                        )
                                    }
                                    else {
                                        return (
                                            <Breadcrumb.Item key={i} linkProps={{ to: url }} linkAs={Link}>
                                                {item.name}
                                            </Breadcrumb.Item>
                                        )
                                    }

                                })}
                            </Breadcrumb>
                        </Container>

                        <div className='grid--product'>
                            {data.map((item, i) => {
                                var urlName = item.name.replace(/[\s-]+/g, '-').toLowerCase();
                                let url = '/product/' + item.id + '-' + urlName;
                                if (item.mainImage !== null) {
                                    var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + item.mainImage
                                }
                                else {
                                    var mainImage = 'https://kateihome.com/defaults/default.png'
                                }

                                return (
                                    <div key={i}>
                                        <div className='item--product'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className='product-grid-image-container'>
                                                                <Link to={url}>
                                                                    <Image className='product-grid-image' src={mainImage} alt={item.name} width={'100%'} height={'auto'} />
                                                                </Link>
                                                            </div>

                                                            <span className='available d-flex align-items-center'>
                                                                {item.isAvailable === '1' ?
                                                                    (
                                                                        <>
                                                                            <FontAwesomeIcon size={'lg'} icon="circle" color="#81a432" className='me-1' />
                                                                            {LanguageProvider.language.checkout.available}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <FontAwesomeIcon size={'lg'} icon="circle" color="#f2dc23" className='me-1' />
                                                                            {LanguageProvider.language.checkout.notAvailable}
                                                                        </>
                                                                    )
                                                                }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <Link to={url}>
                                                                <h3>{item.name}</h3>
                                                            </Link>
                                                            <p className='price'>
                                                                {item.discountedPrice !== null ?
                                                                    (
                                                                        <><s>{item.price}</s> {item.discountedPrice} €</>
                                                                    ) : (
                                                                        <>{item.price} €</>
                                                                    )
                                                                }
                                                            </p>
                                                            {item.variants && item.variants.length > 1 ?
                                                                    (
                                                                        <Link to={url}>
                                                                            <span className='variantsCount'>{item.variants.length} varianti disponibili</span>
                                                                        </Link>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                        </td>
                                                    </tr>
                                                    {sid !== null ?
                                                        (
                                                            <tr className='tr-icons'>
                                                                <td>
                                                                    {item.isInWishlist === '1' ?
                                                                        (
                                                                            <span onClick={() => removeFromWishlist(item.id)} className='product--icon'>
                                                                                <FontAwesomeIcon size={'lg'} icon="heart" color="#ff4400" />
                                                                            </span>
                                                                        ) : (
                                                                            <span onClick={() => addToWishlist(item.id)} className='product--icon'>
                                                                                <FontAwesomeIcon size={'lg'} icon="heart" color="#666" />
                                                                            </span>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <span onClick={() => addToCart(item.id)} className='product--icon'>
                                                                        <FontAwesomeIcon size={'lg'} icon="shopping-cart" color="#666" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Container>
            </>
        );
    }
    else {
        if (data === null) {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <Alert variant='warning'>
                            {LanguageProvider.language.defaults.noResults}
                        </Alert>
                    </div>
                </Container>
            )
        }
    }
};