import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Form, FloatingLabel, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';
import { toast, ToastContainer } from 'react-toastify';


export default function Recover() {

    const navigate = useNavigate();

    const [username, setUserName] = useState('');


    async function handleSubmit(e) {
        e.preventDefault();
        var body = {
            username: username
        };
        const response = await sendDataToApi(body, 'reset_password', null)

        console.log(response)
        if (response.result === 'success') {
            navigate('/reset')
        }
        else {
            toast.error(response.error.VPKey, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    return (
        <Container fluid="md" className="mt-5 mb-5">
            <div className="col-md-6">
                <p className="lead d-flex align-content-center">
                    <Link to="/login">
                        <span role="button"><FontAwesomeIcon size={'sm'} icon="chevron-left" /> {LanguageProvider.language.defaults.goBack}</span>
                    </Link>
                </p>
                <Form onSubmit={handleSubmit}>
                    <div className="mb-3 mt-2">
                        <h3>{LanguageProvider.language.recover.title}</h3>
                    </div>

                    <FloatingLabel label={LanguageProvider.language.login.username} className="mb-3">
                        <Form.Control type="text" placeholder="name@example.com" onChange={e => setUserName(e.target.value)} />
                    </FloatingLabel>

                    <Button variant="primary" size="lg" type="submit">{LanguageProvider.language.recover.send}</Button>
                </Form>
            </div>
            <ToastContainer />
        </Container>
    );
};