import { toast } from 'react-toastify';




function validaCodiceFiscale(cf) {
    var validi, i, s, set1, set2, setpari, setdisp;
    if( cf == '' )  return '';
    cf = cf.toUpperCase();
    if( cf.length != 16 )
        return false;
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for( i = 0; i < 16; i++ ){
        if( validi.indexOf( cf.charAt(i) ) == -1 )
            return false;
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for( i = 1; i <= 13; i += 2 )
        s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
    for( i = 0; i <= 14; i += 2 )
        s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
    if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
        return false;
    return true;
}

function controllaPartitaIVA(pi) {
    if (pi == '') return false;
    else if (!/^[0-9]{11}$/.test(pi)) return false;
    else return true;
}

function controllaSDI(pi) {
    return pi.length === 7
}

export function formCheck(data) {
    var validation = true;
    console.warn(data)
    Object.keys(data).forEach(key => {
        console.log(key, data[key]);
        if(key === 'taxCode' && data[key].length > 0) {
            if(validaCodiceFiscale(data[key])) {

            }
            else {
                if(controllaPartitaIVA(data[key])) {

                }
                else {
                    toast.error('Codice fiscale errato!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    validation = false;
                }
            }
            
        }
        else if(key === 'vatNumber' && data[key].length > 0) {
            if(controllaPartitaIVA(data[key])) {

            }
            else {
                toast.error('La partita iva è errata!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                validation = false;
            }
        }
        else if(key === 'sdi' && data[key].length > 0) {
            if(controllaSDI(data[key])) {

            }
            else {
                toast.error('Il codice SDI è errato!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                validation = false;
            }
        }
    });
    return validation;
}