import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Navbar, Alert, Table, Dropdown, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { updateItem, insertItem, deleteItem, propertiesItem, getItems, searchItems } from '../../core/api';
import ModalView from '../../components/ModalView';
import ModalEdit from '../../components/ModalEditShipping';
import ModalAdd from '../../components/ModalAddShipping';
import ModalDelete from '../../components/ModalDelete';
import PaginationBlock from '../../components/Pagination';


export default function ShippingAddress() {
    //config
    const [entity] = useState("_shipping_address")
    const [rowPreview] = useState(["address", "city", "name", "surname", "phoneNumber"])
    const [rowView] = useState(["address", "businessName", "city", "province", "postalCode", "name", "surname", "phoneNumber", "floor", "elevatorSize", "stairsSize", "parking"])
    const [rowEdit] = useState(["address", "businessName", "city", "province", "postalCode", "name", "surname", "phoneNumber", "floor", "elevatorSize", "stairsSize", "parking"])
    const [rowAdd] = useState(["address", "businessName", "city", "province", "postalCode", "name", "surname", "phoneNumber", "floor", "elevatorSize", "stairsSize", "parking"])
    const [searchFields] = useState(["id", "address", "name", "surname", "city", "businessName"])
    const [sorting, setSorting] = useState({ "field": "id", "order": "desc" })
    const [defaultLabel] = useState('address')
    const [tableConfig, setTableConfig] = useState(null)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [limit, setLimit] = useState(10)

    //modals content
    const [modalViewData, setModalViewData] = useState(null)
    const [modalEditData, setModalEditData] = useState(null)
    const [modalAddData, setModalAddData] = useState(null)
    const [modalDeleteData, setModalDeleteData] = useState(null)

    //data
    const [data, setData] = useState(null)
    const [search, setSearch] = useState(null)


    useEffect(() => {
        refreshData()
    }, [page, limit]);

    useEffect(() => {
        getProperties()
    }, []);


    async function getProperties() {
        let data = await propertiesItem(entity)
        if (data.result === 'success') {
            setTableConfig(data.data.data);
        }
    }
    function refreshData() {
        if (search !== '') {
            searchData(page, limit)
        }
        else {
            getData(page, limit)
        }
    }
    async function getData(page, limit) {
        let data = await getItems(page, limit, entity, searchFields, sorting)
        if (data.result === 'success') {
            setData(data.data.data);
            setTotalPages(data.data.pagesCount)
        }
    }
    async function searchData(page, limit) {
        let data = await searchItems(page, limit, search, entity, searchFields, sorting)
        if (data.result === 'success') {
            setData(data.data.data);
            setTotalPages(data.data.pagesCount)
        }
    }




    /* modals */
    /* view */
    function view(i) {
        let item = data[i]
        setModalViewData(item)
    }
    function closeModalView() {
        setModalViewData(null)
    }
    /* edit */
    function edit(i) {
        let item = data[i]
        setModalEditData(item)
    }
    function closeModalEdit() {
        setModalEditData(null)
    }
    async function saveModalEdit(id, data) {
        let result = await updateItem(id, data, entity)
        if (result === 'success') {
            closeModalEdit()
            refreshData()
        }
    }
    /* remove */
    function remove(i) {
        let item = data[i]
        setModalDeleteData(item)
    }
    function closeModalDelete() {
        setModalDeleteData(null)
    }
    async function confirmModalDelete(id) {
        let result = await deleteItem(id, entity)
        if (result === 'success') {
            closeModalDelete()
            refreshData()
        }
    }
    /* add */
    function create() {
        let temp = Date.now();
        setModalAddData(temp)
    }
    function closeModalAdd() {
        setModalAddData(null)
    }
    async function saveModalAdd(data) {
        let result = await insertItem(data, entity)
        if (result === 'success') {
            closeModalAdd()
            refreshData()
        }
    }


    if (data !== null) {
        return (
            <Container fluid="md" className="container-lg mt-3 mb-5">

                <h2>{LanguageProvider.language.profile.shippingAddress}</h2>

                <Link to='/profile'>
                    <Button className='mb-3' size={'sm'} variant="outline-secondary">
                        <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                    </Button>
                </Link>

                <Card>
                    <Card.Header>
                        <Navbar bg="light" expand="lg">
                            <Container fluid>
                                <Button className="me-2" variant="primary" onClick={() => create()}>{LanguageProvider.language.defaults.create}</Button>
                                <div className="d-flex">
                                    <Form.Control onChange={(event) => setSearch(event.target.value)} type="search" placeholder={LanguageProvider.language.defaults.search} className="me-2" />
                                    <Button variant="outline-success" onClick={() => { setPage(1); searchData(1, limit); }}>{LanguageProvider.language.defaults.search}</Button>
                                </div>
                            </Container>
                        </Navbar>
                    </Card.Header>
                    <Card.Body>
                        {data.length > 0 ? (
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        {rowPreview.map((item, i) => {
                                            return (
                                                <th key={i}>{LanguageProvider.language.labels[item]}</th>
                                            )
                                        })}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return (
                                            <tr key={i} onDoubleClick={() => view(i)}>
                                                {rowPreview.map((key, j) => {
                                                    return (
                                                        <td key={j}>
                                                            {item[key]}
                                                        </td>
                                                    )
                                                })}
                                                <td>
                                                    <Dropdown className='position-static'>
                                                        <Dropdown.Toggle size="sm" variant="secondary" id="dropdownMenuRow">
                                                            <FontAwesomeIcon size={'lg'} icon="cog" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => view(i)}>{LanguageProvider.language.defaults.view}</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => edit(i)}>{LanguageProvider.language.defaults.edit}</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => remove(i)}>{LanguageProvider.language.defaults.delete}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <>
                                <Alert variant="warning">
                                    {LanguageProvider.language.defaults.noResults}
                                </Alert>
                            </>
                        )}
                    </Card.Body>
                </Card>


                <PaginationBlock page={page} totalPages={totalPages} limit={limit} setLimit={setLimit} setPage={setPage} />

                <ModalView tableConfig={tableConfig} data={modalViewData} rowView={rowView} closeModalView={closeModalView} />
                <ModalDelete defaultLabel={defaultLabel} data={modalDeleteData} closeModalDelete={closeModalDelete} confirmModalDelete={confirmModalDelete} />
                <ModalEdit entity={entity} tableConfig={tableConfig} data={modalEditData} rowEdit={rowEdit} closeModalEdit={closeModalEdit} saveModalEdit={saveModalEdit} />
                <ModalAdd entity={entity} tableConfig={tableConfig} data={modalAddData} rowAdd={rowAdd} closeModalAdd={closeModalAdd} saveModalAdd={saveModalAdd} />
            </Container>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};