import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Alert, Table, Image, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';
import { settings } from '../../core/settings';
import moment from 'moment';


export default function Orders() {
    //config
    const [entity] = useState("get_user_orders")

    //data
    const [data, setData] = useState(null)


    useEffect(() => {
        getData()
    }, []);


    async function getData() {
        let data = await sendDataToApi({}, entity)
        if (data.result === 'success') {
            setData(data.data.data);
            console.log(data)
        }
    }


    if (data !== null) {
        return (
            <Container fluid="md" className="container-lg mt-3 mb-5">

                <h2>{LanguageProvider.language.profile.orders}</h2>

                <Link to='/profile'>
                    <Button className='mb-3' size={'sm'} variant="outline-secondary">
                        <FontAwesomeIcon size={'lg'} icon="chevron-left" />
                    </Button>
                </Link>

                <Card>
                    <Card.Body>
                        {data.length > 0 ? (
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>{LanguageProvider.language.order.date}</th>
                                        <th>{LanguageProvider.language.order.deliveryDate}</th>
                                        <th>{LanguageProvider.language.order.address}</th>
                                        <th>{LanguageProvider.language.order.products}</th>
                                        <th>{LanguageProvider.language.order.total}</th>
                                        <th>{LanguageProvider.language.order.status}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    {moment(item.orderDatetime).format('DD/MM/YYYY')}
                                                </td>
                                                <td>
                                                    {item.deliveryDate !== null &&
                                                        <>
                                                            {moment(item.deliveryDate).format('DD/MM/YYYY')}
                                                            <br/>
                                                            {item.deliveryTimeInterval}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {item.shippingAddress !== null &&
                                                        <>
                                                            {item.shippingAddress.city}, {item.shippingAddress.postalCode}<br/>
                                                            {item.shippingAddress.address}<br/>
                                                            {item.shippingAddress.name} {item.shippingAddress.surname}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{LanguageProvider.language.order.name}</th>
                                                                <th>{LanguageProvider.language.order.quantity}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {item.products.map((product, i) => {
                                                            var product_url = "/product/" + product.product.id
                                                            if (product.product.mainImage !== null) {
                                                                var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + product.product.mainImage.id
                                                            }
                                                            else {
                                                                var mainImage = 'https://kateihome.com/defaults/default.png'
                                                            }
                                                            return (
                                                                <tr key={i}>
                                                                    <td style={{width: '50px'}}>
                                                                        <Link to={product_url}>
                                                                            <img src={mainImage} width={50} />
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={product_url}>{product.product.name}</Link>
                                                                        <br />
                                                                        {product.product.price} €
                                                                    </td>
                                                                    <td style={{width: '30px'}}>
                                                                        {product.quantity}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                                <td>
                                                    {LanguageProvider.language.order.totalProducts} {item.totals.products} €<br/>
                                                    {LanguageProvider.language.order.totalShipping} {item.totals.shipping} €<br/>
                                                    {LanguageProvider.language.order.totalOrder} {item.totals.total} €<br/>
                                                </td>
                                                <td>{item.status.label}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <>
                                <Alert variant="warning">
                                    {LanguageProvider.language.defaults.noResults}
                                </Alert>
                            </>
                        )}
                    </Card.Body>
                </Card>


            </Container>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};