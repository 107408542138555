import React, { useState, useEffect } from 'react';
import './scss/style.scss';
import './css/globals.css'
import './css/common.css'
import './css/header.css'
import './css/footer.css'
import './css/custom.css'
import './css/category.css'
import './css/product.css'
import './css/brand.css'
import './css/blog.css'
import './css/searchbar.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


/** pages */
import Home from './pages/index';
import NotFound from './pages/not_found';
import About from './pages/about';
import Contact from './pages/contact';
import Terms from './pages/terms';
import Blog from './pages/blog/index';
import Entry from './pages/entry/index';
import Brands from './pages/brands';
import Brand from './pages/brand/index';
import Products from './pages/products';
import Categories from './pages/categories/index';
import Category from './pages/category/index';
import Product from './pages/product/index';
import Search from './pages/search';
//user
import Login from './pages/user/login';
import Logout from './pages/user/logout';
import Recover from './pages/user/recover';
import Reset from './pages/user/reset';
import Profile from './pages/user/profile';
import ShippingAddress from './pages/user/shipping_address';
import BillingAddress from './pages/user/billing_address';
import Cart from './pages/user/cart';
import Orders from './pages/user/orders';
import Wishlist from './pages/user/wishlist';
//checkout
import Shipping from './pages/checkout/shipping';
import Warehouses from './pages/checkout/warehouses';
import Payment from './pages/checkout/payment';
import Status from './pages/checkout/status';


import HeaderBlock from './components/HeaderBlock';
import FooterBlock from './components/FooterBlock';
import Seo from './components/Seo';
import { sendDataToApi } from './core/api';

import TagManager from 'react-gtm-module';



function App() {

    library.add(fas)

    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        refreshUserInfo()
    }, []);

    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-5JHV5ZBT' }); 
    }, []);

    

    async function refreshUserInfo() {
        let sid = localStorage.getItem('sid');
        if (sid !== null) {
            const response = await sendDataToApi({}, 'get_user_info', null)
            console.warn('user_info', response)
            if (response.result === 'success') {
                setUserInfo(response.data.data)
            }
        }
    }

    function resetUserInfo() {
        setUserInfo({})
    }
    


    return (
        <Router basename={'/'}>
            <Seo />
            <HeaderBlock userInfo={userInfo} />
            <main>
                <Routes>
                    <Route path="*" element={<NotFound />} /> {/* 404 page */}
                    <Route exact path='/' element={<Home />} /> {/* default page */}
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path="/blog/:p" element={<Blog />} />
                    <Route path="/entry/:k" element={<Entry />} />
                    <Route path='/brands' element={<Brands />} />
                    <Route path="/brand/:k" element={<Brand />} />
                    <Route path='/products' element={<Products />} />
                    <Route path="/categories/:k" element={<Categories />} />
                    <Route path="/category/:k" element={<Category userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path="/product/:k" element={<Product userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/search' element={<Search />} />
                    {/* user */}
                    <Route path='/login' element={<GoogleReCaptchaProvider reCaptchaKey="6LeVhI0iAAAAAAybbjxt4nXmc84yO8cfBBcGXzTi"><Login userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} /></GoogleReCaptchaProvider>} />
                    <Route path='/logout' element={<Logout userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/recover' element={<Recover userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/reset' element={<Reset userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/profile' element={<Profile userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/shipping_address' element={<ShippingAddress userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/billing_address' element={<BillingAddress userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/cart' element={<Cart userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/wishlist' element={<Wishlist userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/orders' element={<Orders userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    {/* checkout */}
                    <Route path='/checkout/shipping' element={<Shipping userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/checkout/warehouses' element={<Warehouses userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/checkout/payment' element={<Payment userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                    <Route path='/checkout/status' element={<Status userInfo={userInfo} refreshUserInfo={refreshUserInfo} resetUserInfo={resetUserInfo} />} />
                </Routes>
            </main>
            <FooterBlock />
        </Router>
    );
   
    
}

export default App;