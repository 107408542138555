import React, { useEffect } from 'react';
import { Container, Row, Col, Pagination, Dropdown } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';


export default function PaginationBlock({ page, totalPages, limit, setLimit, setPage }) {

    useEffect(() => {
        //
    }, []);


    function goToPrevPage() {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    function goToNextPage() {
        if (page < totalPages) {
            setPage(page + 1)
        }
    }
    function goToFirstPage() {
        if (page !== 1) {
            setPage(1)
        }
    }
    function goToLastPage() {
        if (page !== totalPages) {
            setPage(totalPages)
        }
    }


    return (
        <Container className="mt-4 mb-4" fluid={"md"}>
            <Row>
                <Col sm={8}>
                    <Pagination>
                        <Pagination.First className={page === 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                        <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                        <Pagination.Item key={page}>
                            {page}
                        </Pagination.Item>
                        <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                        <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                    </Pagination>
                </Col>
                <Col sm={4} className="text-sm-end">
                    <Dropdown>
                        <Dropdown.Toggle className="me-3" variant="outline-secondary" id="dropdown-resultPerPage">
                            {LanguageProvider.language.defaults.resultsPerPage} {limit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setLimit(10)}>10</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLimit(20)}>20</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLimit(50)}>50</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
    );
};