import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Breadcrumb, Row, Col, Button, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi } from '../../core/api';
import { settings } from '../../core/settings';


export default function C_Payment() {


    /*

    login paypal
    clicchi avatar, impostazioni conto, menu "dati aziendali" -> codice commerciante

    SANDBOX
    account principale:
    sb-r6cnf24883573@business.example.com
    client ID: AbbiYrfaSS7EBs6SODlF460PNndRyhhchBJFmsWzj_j57VENHXqQgpa2urInYQaLu9I0RUUerzzNiApk
    secret key: EOyMCILh8Fl4RmhGhSSGEenMasgK_qu9ZbmNXMGsbuJCN7JLjf3VLqkrg71ho0nfjszM1qQXY74RIrY6


    business 1: // brugnera logistica
    2A2X4JDYSCYXQ
    sb-vbozg26428369@business.example.com
    Ov3n9bk.

    Business 2 // rigo salotti 
    XKAE229CPLPKJ
    sb-or5kt14357353@business.example.com
    -:=R7kYa

    Business 3 // katei
    M7JP9Y4D5YE9G
    sb-8klcr26428368@business.example.com
    NB9GK=hg


    Account personale per pagamento:
    sb-xu7gg24192056@personal.example.com
    s_U743nF


    */


    const navigate = useNavigate();

    //data
    const [summary, setSummary] = useState(null)
    const [payments, setPayments] = useState(null)
    const [merchant, setMerchant] = useState(null)
    const [orderTotal, setOrderTotal] = useState(null)


    useEffect(() => {
        init()
    }, []);

    async function init() {
        await getSummary()
    }


    async function getSummary() {
        let data = await sendDataToApi({}, 'get_pending_order_summary')
        console.warn('summary', data)
        setSummary(data.data.data)
        var p = data.data.data.payments
        var ms = ''
        var pus = []
        for (let i = 0; i < p.length; i++) {
            if(ms === null || ms === '') {
                ms = p[i].paypalMerchantId
            }
            else {
                ms = ms + ',' + p[i].paypalMerchantId
            }
            
            var pu = {
                reference_id: p[i].paypalReferenceId,
                payee: {
                    email_address: p[i].paypalEmail,
                },
                amount: {
                    currency_code: 'EUR',
                    value: p[i].total,
                },
                payment_instruction: {
                    disbursement_mode: "INSTANT"
                }
            }
            pus.push(pu)
        }

        console.warn(pus)
        setMerchant(ms)
        setPayments(pus)
    }

    useEffect(() => {
        if(merchant !== null) {
            let n = summary.totals.total
            var o = (n.replace('.','').replace(',','.'))
            setOrderTotal(o)
        }
    }, [merchant]);

    useEffect(() => {
        if(orderTotal !== null) {
            //var clientId = 'AVx33zeoEMo38eOirRtCxXZouQylq62MZ-FksmhWAAELedysbr02VEFK6Qzbg6lBn_LYihflW-NDIPiA'
            var clientId = 'AbbiYrfaSS7EBs6SODlF460PNndRyhhchBJFmsWzj_j57VENHXqQgpa2urInYQaLu9I0RUUerzzNiApk'
            var script = document.createElement('script');
            console.warn(merchant)
            //script.setAttribute("data-merchant-id", "2A2X4JDYSCYXQ,XKAE229CPLPKJ,M7JP9Y4D5YE9G");
            script.setAttribute("data-merchant-id", merchant);
            script.onload = function () {
                //do stuff with the script
                //console.warn(script)
                renderPaypal(payments)
            };
            script.src = 'https://www.paypal.com/sdk/js?client-id='+clientId+'&currency=EUR&merchant-id=*';
            document.head.appendChild(script);
        }
    }, [orderTotal]);


    function renderPaypal(payments) {
        window.paypal.Buttons({
            style: {
                layout: 'vertical',
                color:  'blue',
                shape:  'rect',
                label:  'paypal'
            },
            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: payments
                    /*
                    purchase_units: [
                        {
                            reference_id: "REFID-7777",
                            //reference_id: summary.id,
                            payee: {
                                email_address: "sb-vbozg26428369@business.example.com",
                                //merchant_id: "EM2NM3VJSB5MG"
                            },
                            amount: {
                                currency_code: 'EUR',
                                value: 1024,
                            },
                            payment_instruction: {
                                disbursement_mode: "INSTANT"
                            },
                        },
                        {
                            reference_id: "REFID-8888",
                            //reference_id: summary.id,
                            payee: {
                                email_address: "sb-or5kt14357353@business.example.com",
                                //merchant_id: "QHTZSD9SG64TW"
                            },
                            amount: {
                                currency_code: 'EUR',
                                value: 2500,
                            },
                            payment_instruction: {
                                disbursement_mode: "INSTANT"
                            },
                        },
                        {
                            reference_id: "REFID-9999",
                            //reference_id: summary.id,
                            payee: {
                                email_address: "sb-8klcr26428368@business.example.com",
                                //merchant_id: "QHTZSD9SG64TW"
                            },
                            amount: {
                                currency_code: 'EUR',
                                value: 250,
                            },
                            payment_instruction: {
                                disbursement_mode: "INSTANT"
                            },
                        },
                    ]
                    */
                })
            },
            onApprove: function(data, actions) {
                return actions.order.capture().then(function(details) {
                    console.log('payment details')
                    console.log(details)
                    var status = details.status;
                    var id = details.id;
                    if(status === 'COMPLETED') {
                        sendOrderToApi(id)
                    }
                })
            }
        }).render('#paypal-button-container');
        
    }

    async function sendOrderToApi(id) {
        let body = {
            'transactionId': id
        }
        let data = await sendDataToApi(body, 'confirm_paypal_order_payment')
        console.log(data)


        if(data.result === 'success' && data.data.data === true) {
            console.log('pagato')
            var url = '/checkout/status?order='+summary.id+'&transaction='+id+'&status=true'
            navigate(url)

        }
        else {
            var url = '/checkout/status?order='+summary.id+'&transaction='+id+'&status=false'
            navigate(url)
        }
    }



    if (summary !== null) {
        return (
            <>
                <Container fluid="md" className="container-lg mt-3 mb-5">
                    
                    <Breadcrumb>
                        <Breadcrumb.Item linkProps={{ to: '/cart' }} linkAs={Link}>
                            {LanguageProvider.language.checkout.cart}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: '/checkout/shipping' }} linkAs={Link}>
                            {LanguageProvider.language.checkout.shipping}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: '/checkout/warehouses' }} linkAs={Link}>
                            {LanguageProvider.language.checkout.warehouse}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{LanguageProvider.language.checkout.payment}</Breadcrumb.Item>
                    </Breadcrumb>

                    <h2>{LanguageProvider.language.checkout.payment}</h2>

                    <Row>
                        <Col sm={12} md={9} className='p-2'>
                            <Card>
                                <Card.Body>
                                    
                                    <div id="paypal-button-container"></div>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={3} className='pe-3 ps-3 bg-light'>
                            <h4>{LanguageProvider.language.checkout.recap}</h4>
                            <table className='table'>
                                <tbody>
                                    {summary.orderProducts.map((product, i) => {
                                        if (product.product.mainImage !== null) {
                                            var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + product.product.mainImage.id
                                        }
                                        else {
                                            var mainImage = 'https://kateihome.com/defaults/default.png'
                                        }
                                        return (
                                            <tr key={i}>
                                                <td><img src={mainImage} height={40} /></td>
                                                <td>
                                                    {product.product.name} ({product.quantity})<br />
                                                    {product.product.discountedPrice !== null ?
                                                        (
                                                            <>{product.product.discountedPrice} €</>
                                                        ) : (
                                                            <>{product.product.price} €</>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalProducts}</td>
                                        <td className='text-end'>{summary.totals.products} €</td>
                                    </tr>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalShipping}</td>
                                        <td className='text-end'>{summary.totals.shipping} €</td>
                                    </tr>
                                    <tr>
                                        <td>{LanguageProvider.language.checkout.totalOrder}</td>
                                        <td className='text-end'>{summary.totals.total} €</td>
                                    </tr>
                                </tfoot>
                            </table>

                            <Alert variant="info" className='mt-3 text-start '>
                                <p className='d-flex align-items-center mt-0 fw-bold'>
                                    <FontAwesomeIcon size={'sm'} icon="triangle-exclamation" color="#842029" className='me-2' /> {LanguageProvider.language.defaults.alert}
                                </p>
                                {LanguageProvider.language.checkout.message}
                                <br/>
                                <Alert.Link href="/terms">{LanguageProvider.language.defaults.terms}</Alert.Link>
                            </Alert>
                        </Col>
                    </Row>
                    
                </Container>

            </>
        );
    }
    else {
        return (
            <Container fluid>
                <div className='main'>
                    <center>
                        <br />
                        <Spinner animation="border" variant="secondary" />
                    </center>
                </div>
            </Container>
        )
    }
};