import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Button, Tabs, Tab, InputGroup, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { LanguageProvider } from '../../core/language';
import { sendDataToApi, propertiesItem, updateItem, getItem } from '../../core/api';
import { Utils } from '../../core/utils';


export default function Profile() {

    const navigate = useNavigate();
    const [entity] = useState("user")
    const [searchFields] = useState(["id", "name", "surname", "email"])
    const [sorting, setSorting] = useState({ "field": "id", "order": "desc" })

    const [userInfo, setUserInfo] = useState(1);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        let sid = localStorage.getItem('sid');
        if (sid !== null) {
            getData()
        }
        else {
            navigate('/login')
        }
    }, []);


    async function getData() {
        const idUser = JSON.parse(localStorage.getItem('userInfo')).id;
        let data = await getItem(idUser, entity, searchFields, sorting)
        if (data !== null) {
            if (data.result === 'success') {
                var userData = data.data.data[0];
                setUserInfo(userData)
                setName(userData.name)
                setSurname(userData.surname)
                setUsername(userData.username)
            }
        }

    }

    async function saveProfile() {
        const idUser = JSON.parse(localStorage.getItem('userInfo')).id;
        var data = {}
        data.name = name
        data.surname = surname
        data.username = username
        let result = await updateItem(idUser, data, entity)
    }

    async function updatePassword() {
        if (newPassword === confirmPassword) {
            var data = {}
            data.old_password = Utils.encryptPassword(password)
            data.new_password = Utils.encryptPassword(newPassword)
            const response = await sendDataToApi(data, 'user_password_change', null)
            console.log(response)
            if (response.result === 'success') {
                toast.success(LanguageProvider.language.profile.passwordUpdated, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else {
                toast.error(response.error.VPKey, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        else {
            toast.warn(LanguageProvider.language.profile.passwordMatch, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <Container fluid="md" className="container-lg mt-3 mb-5">

            <h2>{LanguageProvider.language.profile.profile}</h2>
            <p className='lead'>{LanguageProvider.language.profile.subtitle}</p>

            <Row className='text-center mb-5 mt-5'>
                <Col xs={3}>
                    <Link to="/wishlist">
                        <FontAwesomeIcon size={'2xl'} icon="heart" color="#444" className='mb-2' />
                        <p className='small'>{LanguageProvider.language.profile.wishlist}</p>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="/orders">
                        <FontAwesomeIcon size={'2xl'} icon="shopping-cart" color="#444" className='mb-2' />
                        <p className='small'>{LanguageProvider.language.profile.orders}</p>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="/shipping_address">
                        <FontAwesomeIcon size={'2xl'} icon="location-dot" color="#444" className='mb-2' />
                        <p className='small'>{LanguageProvider.language.profile.shippingAddress}</p>
                    </Link>
                </Col>
                <Col xs={3}>
                    <Link to="/billing_address">
                        <FontAwesomeIcon size={'2xl'} icon="briefcase" color="#444" className='mb-2' />
                        <p className='small'>{LanguageProvider.language.profile.billingAddress}</p>
                    </Link>
                </Col>
            </Row>

            <Card>
                <Card.Body>
                    <Tabs defaultActiveKey="general" id="tabs-user" className="mb-3">
                        <Tab eventKey="general" title={LanguageProvider.language.profile.general}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.name}</InputGroup.Text>
                                <Form.Control value={name} onChange={e => setName(e.target.value)} />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.surname}</InputGroup.Text>
                                <Form.Control value={surname} onChange={e => setSurname(e.target.value)} />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.username}</InputGroup.Text>
                                <Form.Control value={username} onChange={e => setUsername(e.target.value)} />
                            </InputGroup>

                            <Button onClick={saveProfile} variant="primary" type="submit">{LanguageProvider.language.profile.save}</Button>
                        </Tab>
                        <Tab eventKey="password" title={LanguageProvider.language.profile.password}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.oldPassword}</InputGroup.Text>
                                <Form.Control type='password' value={password} onChange={e => setPassword(e.target.value)} />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.newPassword}</InputGroup.Text>
                                <Form.Control type='password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className='w-25'>{LanguageProvider.language.profile.confirmPassword}</InputGroup.Text>
                                <Form.Control type='password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                            </InputGroup>

                            <Button onClick={updatePassword} variant="primary" type="submit">{LanguageProvider.language.profile.updatePassword}</Button>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>

            <div className='mt-5 mb-3'>
                <p className='d-flex justify-content-end'>
                    <Link to="/logout">
                        <Button variant="danger">{LanguageProvider.language.profile.logout}</Button>
                    </Link>
                </p>
            </div>

        </Container>
    );
};