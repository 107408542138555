    import React, { useState, useEffect } from 'react';
    import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
    import { Container, Card, Breadcrumb, Row, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { LanguageProvider } from '../../core/language';
    import { sendDataToApi } from '../../core/api';
    import { settings } from '../../core/settings';


    export default function C_Warehouses() {

        const navigate = useNavigate();

        //data
        const [warehouses, setWarehouses] = useState(null)
        const [selectedWare, setSelectedWare] = useState(null)
        const [summary, setSummary] = useState(null)
        const [shippingPricePlaceholder, setShippingPricePlaceholder] = useState('-')
        const [totalOrder, setTotalOrder] = useState(0)


        useEffect(() => {
            init()
        }, []);

        async function init() {
            await getSummary()
            await getWarehouses()
        }


        async function getSummary() {
            let data = await sendDataToApi({}, 'get_pending_order_summary')
            console.log('summary')
            console.log(data)
            setSummary(data.data.data)
            setTotalOrder(data.data.data.totals.products)
        }

        async function getWarehouses() {
            let data = await sendDataToApi({}, 'get_pending_order_available_warehouses')
            console.log('warehouses')
            console.log(data)
            setWarehouses(data.data.data)
            if(data.data.data.length > 0) {
                setSelectedWare(data.data.data[0].warehouse.id)
            }
        }


        function changeWarehouse(item) {
            setShippingPricePlaceholder(item.shippingPrice)
            setSelectedWare(item.warehouse.id)
            let t = parseFloat(summary.totals.products.replace('.', "").replace(',', ".")) + parseFloat(item.shippingPrice.replace('.', "").replace(',', "."))
            let pt = t.toFixed(2).replace('.', ",").replace(/\d(?=(\d{3})+\.)/g, '$&.')
            setTotalOrder(pt)
        }

        async function goToPayment() {
            if(selectedWare !== null) {
                let send = {
                    idWarehouse: selectedWare
                }
                let data = await sendDataToApi(send, 'set_pending_order_warehouse')
                console.log(data)
                navigate('/checkout/payment')
            }
            else {
                alert('Select warehouse!')
            }
        }



        if (summary !== null && warehouses !== null) {
            return (
                <>
                    <Container fluid="md" className="container-lg mt-3 mb-5">

                        <Breadcrumb>
                            <Breadcrumb.Item linkProps={{ to: '/cart' }} linkAs={Link}>
                                {LanguageProvider.language.checkout.cart}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkProps={{ to: '/checkout/shipping' }} linkAs={Link}>
                                {LanguageProvider.language.checkout.shipping}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{LanguageProvider.language.checkout.warehouse}</Breadcrumb.Item>
                        </Breadcrumb>

                        <h2>{LanguageProvider.language.checkout.selectWarehouse}</h2>

                        <Row>
                            <Col sm={12} md={9} className='p-2'>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                        {warehouses.map((item, i) => {
                                            let type = 'radio'
                                            return (
                                                <div key={i}>
                                                    
                                                    <div key={type} className="mb-3">
                                                        <Form.Check type={type} defaultChecked={selectedWare}>
                                                            <Form.Check.Input type={type} onChange={() => changeWarehouse(item)} name='ware' value={item.warehouse.id} />
                                                            <Form.Check.Label>
                                                                <Card style={{ width: '300px' }}>
                                                                    <Card.Body>
                                                                        <Card.Title>{item.warehouse.name}</Card.Title>
                                                                        <Card.Subtitle className="mb-2 text-muted">{item.warehouse.address}, {item.warehouse.city}</Card.Subtitle>
                                                                        <table className='table'>
                                                                            <tbody>
                                                                                {item.products.map((product, i) => {
                                                                                    if (product.product.mainImage !== null) {
                                                                                        var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + product.product.mainImage.id
                                                                                    }
                                                                                    else {
                                                                                        var mainImage = 'https://kateihome.com/defaults/default.png'
                                                                                    }
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td><img src={mainImage} height={30} /></td>
                                                                                            <td>
                                                                                                {product.product.name}<br />
                                                                                                {product.quantity <= product.quantityAvailable ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <FontAwesomeIcon size={'lg'} icon="circle" color="#81a432" className='me-2' />
                                                                                                            {LanguageProvider.language.checkout.available}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <FontAwesomeIcon size={'lg'} icon="circle" color="#f2dc23" className='me-2' />
                                                                                                            {LanguageProvider.language.checkout.notAvailable}
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </Card.Body>
                                                                    <Card.Footer className="text-muted">
                                                                        {LanguageProvider.language.checkout.shippingDays}: <b>{item.shippingDays}</b>
                                                                        <br/>
                                                                        {LanguageProvider.language.checkout.shippingPrice}: <b>{item.shippingPrice} €</b>
                                                                    </Card.Footer>
                                                                </Card>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        </Form>
                                        <br/>
                                        <center>
                                            <Button size={'lg'} variant="primary" onClick={goToPayment}>{LanguageProvider.language.checkout.goToPayment}</Button>
                                        </center>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} md={3} className='pe-3 ps-3 bg-light'>
                                <h4>{LanguageProvider.language.checkout.recap}</h4>
                                <table className='table'>
                                    <tbody>
                                        {summary.orderProducts.map((product, i) => {
                                            if (product.product.mainImage !== null) {
                                                var mainImage = settings.domain + "/backoffice/app/api/?action=get_resource&raw&id=" + product.product.mainImage.id
                                            }
                                            else {
                                                var mainImage = 'https://kateihome.com/defaults/default.png'
                                            }
                                            return (
                                                <tr key={i}>
                                                    <td><img src={mainImage} height={40} /></td>
                                                    <td>
                                                        {product.product.name} ({product.quantity})<br />
                                                        {product.product.discountedPrice !== null ?
                                                            (
                                                                <>{product.product.discountedPrice} €</>
                                                            ) : (
                                                                <>{product.product.price} €</>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>{LanguageProvider.language.checkout.totalProducts}</td>
                                            <td className='text-end'>{summary.totals.products} €</td>
                                        </tr>
                                        <tr>
                                            <td>{LanguageProvider.language.checkout.totalShipping}</td>
                                            <td className='text-end'>{shippingPricePlaceholder} €</td>
                                        </tr>
                                        <tr>
                                            <td>{LanguageProvider.language.checkout.totalOrder}</td>
                                            <td className='text-end'>{totalOrder} €</td>
                                        </tr>
                                    </tfoot>
                                </table>

                                <Alert variant="info" className='mt-3 text-start '>
                                    <p className='d-flex align-items-center mt-0 fw-bold'>
                                        <FontAwesomeIcon size={'sm'} icon="triangle-exclamation" color="#842029" className='me-2' /> {LanguageProvider.language.defaults.alert}
                                    </p>
                                    {LanguageProvider.language.checkout.message}
                                    <br/>
                                    <Alert.Link href="/terms">{LanguageProvider.language.defaults.terms}</Alert.Link>
                                </Alert>
                            </Col>
                        </Row>
                        

                    </Container>

                </>
            );
        }
        else {
            return (
                <Container fluid>
                    <div className='main'>
                        <center>
                            <br />
                            <Spinner animation="border" variant="secondary" />
                        </center>
                    </div>
                </Container>
            )
        }
    };