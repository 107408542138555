import _ from "lodash"; // cool kids know _ is low-dash
import { MD5 } from "./md5-js";
import { SHA1 } from "./sha1-js";

function encryptPassword(password) {
    //let np = SHA1.sha1(MD5.md5(password))
    let np = SHA1.sha1(password)
    return np
}

function removeFromArray (array, value) {
    var i = array.indexOf(value);
    if (i > -1) {
        array.splice(i, 1);
    }

    return array;
}

function cloneObject(original){
    // copy
    var clone = _.cloneDeep(original);
    return clone;
}

export const Utils = {
    cloneObject: cloneObject,
    removeFromArray: removeFromArray,
    encryptPassword: encryptPassword
};