export const seo = {
    default: {
        title: 'Katei Home',
        description: 'Katei Home non è un semplice e-commerce dedicato al mondo dell’arredamento, ma un modo di pensare. Pensare in grande per ridurre al minimo la distanza tra produttori e clienti, attraverso una serie di servizi vantaggiosi ideati per portare a casa vostra tutta la qualità dei nostri prodotti, a un prezzo sempre conveniente.'
    },
    about: {
        title: 'Chi siamo | Katei Home',
        description: 'Katei Home non è un semplice e-commerce dedicato al mondo dell’arredamento, ma un modo di pensare.'
    },
    contact: {
        title: 'Contatti | Katei Home',
        description: 'Contattaci, ti risponderemo il prima possibile.'
    },
    products: {
        title: 'Prodotti | Katei Home',
        description: 'Visualizza tutti i prodotti.'
    },
    promo: {
        title: 'Promozioni | Katei Home',
        description: 'Rimani sempre aggiornato con le ultime promozioni'
    },
    brands: {
        title: 'Brand | Katei Home',
        description: 'Ecco tutti i nostri brand'
    },
    entry: {
        title: 'Articolo',
        description: ''
    },
    blog: {
        title: 'Blog | Katei Home',
        description: 'Scopri le ultime notizie'
    },
    profile: {
        title: 'Profilo | Katei Home',
        description: 'Gestisci il tuo profilo'
    },
    wishlist: {
        title: 'Lista dei desideri | Katei Home',
        description: 'Ecco la tua lista dei desideri'
    },
    orders: {
        title: 'Storico ordini | Katei Home',
        description: 'Consulta lo storico dei tuoi ordini'
    },
    login: {
        title: 'Effettua il login | Katei Home',
        description: 'Effettua il login'
    },
    terms: {
        title: 'Termini e condizioni | Katei Home',
        description: 'Termini e condizioni'
    }
    
};