import React, { useState, useEffect } from 'react';
import { Container, Navbar, Form, Button, InputGroup } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language';


export default function SearchStandard({ setSearchParent }) {
  
    const [search, setSearch] = useState('')

    async function searchData() {
        if(search !== null && search !== '') {
            setSearchParent(search)
        }
        else {
            setSearch('')
            setSearchParent('')
        }
    }

    function eraseData() {
        setSearch('')
        setSearchParent('')
    }

 
    return (
        <>
            <Navbar expand="lg">
                <Container fluid className='justify-content-center'>
                    <div className="d-flex justify-content-end">
                        <InputGroup className="mb-3">
                            <Form.Control value={search} onChange={(event) => setSearch(event.target.value)} type="text" placeholder={LanguageProvider.language.defaults.search} />
                            {search.length > 0 ? ( 
                                <Button variant="outline-secondary" onClick={() => { eraseData(); }}>x</Button>
                            ):(
                                <></>
                            )}
                            <Button variant="outline-success" onClick={() => { searchData(); }}>{LanguageProvider.language.defaults.search}</Button>
                        </InputGroup>
                    </div>
                </Container>
            </Navbar>
        </>
    );
};