import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { seo } from '../../core/seo';
import { Helmet } from "react-helmet";


export default function Seo() {

    const [metaTitle, setMetaTitle] = useState(seo.default.title)
    const [metaDescription, setMetaDescription] = useState(seo.default.description)

    let location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("category", "categories", "blog", "entry", "product")) {
            //salto
        }
        else {
            let page = location.pathname.split('/').reverse()[0];
            if (page !== '') {
                if (seo.hasOwnProperty(page)) {
                    setMetaTitle(seo[page].title)
                    setMetaDescription(seo[page].description)
                }
                else {
                    setMetaTitle(seo.default.title)
                    setMetaDescription(seo.default.description)
                }
            }
            else {
                setMetaTitle(seo.default.title)
                setMetaDescription(seo.default.description)
            }
        }
    }, [location]);



    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} data-react-helmet="true" />
        </Helmet>
    );
};