import React, { useState, useEffect } from 'react';
import { Container, Link } from 'react-bootstrap';
import { LanguageProvider } from '../core/language';


export default function Promo() {

    useEffect(() => {

    }, []);


    return (
        <Container fluid="md" className='mt-4 mb-4'>
            <div className='main'>
                <h2>{LanguageProvider.language.defaults.terms}</h2>
            </div>
        </Container>
    );
};