import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner } from 'react-bootstrap';
import { sendDataToApi } from '../../core/api';


export default function Entry() {

    const [errors, setErrors] = useState('');
    const { k } = useParams();
    const id = parseInt(k)

    const [data, setData] = useState(null)
    const [entity] = useState("get_public_news")
    const [searchFields] = useState(["id", "title", "metadescription", "metatitle"])
    const [sorting, setSorting] = useState({ "field": "id", "order": "desc" })


    useEffect(() => {
        console.log(id)
        getData(id)
    }, [id]);

    async function getData(id) {
        var body = {
            "query": "",
            "fields": searchFields,
            "page": 0,
            "constraints": {
                "id": {
                    "type": "value",
                    "value": id
                }
            },
            "sorting": sorting,
            "options": {
                "pageResultsCount": 1
            }
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success' && data.data.data.length) {
            setData(data.data.data[0]);
        }
        else {
            console.log(data)
            setData({})
        }
    }



    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>{data.metatitle}</title>
                    <meta name="description" content={data.metadescription} />
                </Helmet>

                <Container fluid='md' className='mt-5 mb-5'>
                    <Container fluid='xs'>
                        <h1>{data.title}</h1>
                        <p className='small text-muted'>{data.date}</p>
                        <div className='content' dangerouslySetInnerHTML={{ __html: data.content }}></div>
                    </Container>
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};