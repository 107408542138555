import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language';
import logo from '../../assets/logo-bianco-katei.png';
import { sendDataToApi } from '../../core/api';


export default function HeaderBlock({userInfo}) {

    const [menuClass, setMenuClass] = useState('')
    const [page, setPage] = useState('')

    function menuToggle() {
        if (menuClass === "") {
            setMenuClass("menu--active")
        }
        else {
            setMenuClass("")
        }
    }

    let location = useLocation();
    useEffect(() => {
        setMenuClass("")
        let location_page = location.pathname;
        setPage(location_page)
    }, [location]);

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);


    /* subheader */
    const [entity] = useState("get_user_categories")
    const [data, setData] = useState(null)

    useEffect(() => {
        getData()
    }, []);

    async function getData() {
        var body = {
            "getAlsoEmptyCategories": true,
            "idProductCategoryParent": null,
            "languageCode": LanguageProvider.getLanguageCode()
        };
        let data = await sendDataToApi(body, entity);
        if (data.result === 'success') {
            console.log("menu --> ", data.data.data)
            setData(data.data.data.categories);
        }
        else {
            console.log(data)
            setData({})
        }
    }


    const [activeCategory, setActiveCategory] = useState(null)



    return (
        <div onMouseLeave={() => setActiveCategory(null)}>
            <header className={menuClass}>
                <nav>
                    <div className='logo'>
                        <Link to="/">
                            <Image src={logo} alt='Katei Home' width={55} height={55} />
                        </Link>
                    </div>
                    <span id='burger--menu' onClick={menuToggle}>MENU</span>
                    <div className='menu'>
                        <ul>
                            <li className={(page === '/about' ? 'active' : '')}>
                                <Link to="/about">
                                    {LanguageProvider.language.header.about}
                                </Link>
                            </li>
                            <li className={(page === '/brands' ? 'active' : '')}>
                                <Link to="/brands">
                                    {LanguageProvider.language.header.brand}
                                </Link>
                            </li>
                            <li className={(page === '/products' ? 'active' : '')}>
                                <Link to="/products">
                                    {LanguageProvider.language.header.products}
                                </Link>
                            </li>
                            <li className={(page === '/profile' ? 'active' : '')}>
                                <Link to="/profile">
                                    {LanguageProvider.language.header.profile}
                                </Link>
                            </li>
                            <li className={(page === '/blog/[pid]' ? 'active' : '')}>
                                <Link to="/blog/1">
                                    {LanguageProvider.language.header.news}
                                </Link>
                            </li>
                            <li className={(page === '/contact' ? 'active' : '')}>
                                <Link to="/contact">
                                    {LanguageProvider.language.header.contact}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    
                    <div id='search-btn'>
                        <Link to="/search">
                            <span className='product--icon'>
                                <FontAwesomeIcon size={'lg'} icon="magnifying-glass" color={"#fff"} />
                            </span>
                        </Link>
                    </div>
                    <div id='user-btn'>
                        <Link to="/profile">
                            <span className='product--icon'>
                                <FontAwesomeIcon size={'lg'} icon="user" color={"#fff"} />
                            </span>
                        </Link>
                    </div>
                    <div id='cart-btn'>
                        <Link to="/cart">
                            <span className='product--icon'>
                                <FontAwesomeIcon size={'lg'} icon="cart-shopping" color={"#fff"} />
                            </span>
                        </Link>
                        {userInfo && (Object.keys(userInfo).length > 0) &&
                            <>
                                <span className='number-cart'>
                                    {userInfo.cartProductNumber}
                                </span>
                            </>
                        }
                    </div>
                </nav>
            </header>

            <div className='subheader'>
                {data !== null && typeof data !== 'undefined' ? (
                    <>
                        {data.map((item, i) => {
                            var urlName = item.category.name.replace(/[\s-]+/g, '-').toLowerCase();
                            if (item.info.hasChild === true) {
                                var url = '/categories/' + item.category.id + '-' + urlName;
                            }
                            else {
                                var url = '/category/' + item.category.id + '-' + urlName;
                            }
                            return (
                                <Link key={i} to={url}>
                                    <li onMouseEnter={() => setActiveCategory(item.category.id)}>{item.category.name}</li>
                                </Link>
                            )
                        })}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data !== null && activeCategory !== null && (
                <div className='sub-subheader'>
                    {data.map((item, i) => {
                        if(item.category.id === activeCategory && item.info.hasChild === true && item.info.childs !== null) {
                            return (
                                <div key={i}>
                                    {(item.info.childs).map((child, j) => {
                                        var urlName = item.category.name.replace(/[\s-]+/g, '-').toLowerCase();
                                        if (child.info.hasChild === true) {
                                            var url = '/categories/' + child.category.id + '-' + urlName;
                                        }
                                        else {
                                            var url = '/category/' + child.category.id + '-' + urlName;
                                        }
                                        return (
                                            <Link key={j} to={url}>
                                                <li className='text-center mb-3'>
                                                    <Image src={child.category.previewImagePath} width='180' height={70} style={{backgroundColor: '#333'}} />
                                                    <br/>
                                                    <span className='small'>{child.category.name}</span>
                                                </li>
                                            </Link>
                                        )
                                    })}
                                </div>
                            )
                            
                        }
                    })}
                </div>
            )}
            
        </div>
    );
};