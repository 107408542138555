import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import { LanguageProvider } from '../../core/language';


export default function ModalDelete({ data, closeModalDelete, confirmModalDelete, defaultLabel }) {

    const [statusModalDelete, setStatusModalDelete] = useState(false)

    useEffect(() => {
        if (data === null) {
            setStatusModalDelete(false)
        }
        else {
            setStatusModalDelete(true)
        }
    }, [data]);


    function confirmModalDeleteFun() {
        confirmModalDelete(data.id)
    }

    if (data !== null) {
        return (
            <>
                <Modal show={statusModalDelete} onHide={closeModalDelete} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.language.defaults.deleteTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{LanguageProvider.language.defaults.deleteMsg}</p>
                        {typeof (data[defaultLabel]) !== 'undefined' ?
                            (
                                <code>{data.id}) {data[defaultLabel]}</code>
                            ) : (
                                <></>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={confirmModalDeleteFun}>
                            {LanguageProvider.language.defaults.delete}
                        </Button>
                        <Button variant="secondary" onClick={closeModalDelete}>
                            {LanguageProvider.language.defaults.close}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};