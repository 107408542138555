import React from 'react';
import { Container } from 'react-bootstrap';
import notfound from '../assets/404.svg';


export default function NotFound() {
    return (
        <Container fluid='md' className='mt-5 mb-5'>

            <div className='text-center'>
                <img src={notfound} width={'50%'} />
            </div>

        </Container>
    );
};