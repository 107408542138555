import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { LanguageProvider } from '../core/language';
import mainImage from '../assets/images/banner-chi-siamo.jpg';
import casa from '../assets/images/casa.svg';
import montaggio from '../assets/images/montaggio.svg';
import spedizione from '../assets/images/spedizione.svg';


export default function About() {
    return (
        <Container fluid className='p-0'>
            <div className='main'>

                <div className='slide-image' style={{ "backgroundImage": `url('${mainImage}')` }}>
                    <h2>{LanguageProvider.language.header.about}</h2>
                </div>

                <Row className='text-center mb-5 mt-5' style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Col sm={4} className='p-5'>
                        <Image src={casa} width={48} height={48} className='mb-3' />
                        <p><b>{LanguageProvider.language.about.title1}</b></p>
                        <p>{LanguageProvider.language.about.subtitle1}</p>
                    </Col>
                    <Col sm={4} className='p-5'>
                        <Image src={spedizione} width={48} height={48} className='mb-3' />
                        <p><b>{LanguageProvider.language.about.title2}</b></p>
                        <p>{LanguageProvider.language.about.subtitle2}</p>
                    </Col>
                    <Col sm={4} className='p-5'>
                        <Image src={montaggio} width={48} height={48} className='mb-3' />
                        <p><b>{LanguageProvider.language.about.title3}</b></p>
                        <p>{LanguageProvider.language.about.subtitle3}</p>
                    </Col>
                </Row>

                <br />

                <Container className="mt-0 mb-5">
                    <p>{LanguageProvider.language.about.description}</p>
                </Container>

            </div>
        </Container>
    );
};